import React, { useEffect } from "react";
import {
  Modal,
  Grid,
  Col,
  Text,
  RingProgress,
  createStyles,
  Loader,
} from "@mantine/core";
import { useGetUsageStatisticsMutation } from "../features/api/apiSlice";
import { ModalConfig } from "../features/modal/types";
import { closeModal } from "../features/modal/modalSlice";
import { useDispatch } from "react-redux";

const useStyles = createStyles(() => ({
  title: {
    fontWeight: "bold",
    fontSize: "24px",
    textAlign: "center",
    margin: "0 auto",
  },
  body: {
    padding: "40px",
  },
  root: {
    textAlign: "center",
    marginTop: 35,
    minHeight: 100,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  ring: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "200px",
  },
}));

const UsageQuotaModal: React.FC<{ config: ModalConfig }> = ({ config }) => {
  const { classes } = useStyles();
  const [getUsageStatistics, { data: usageData, isLoading }] =
    useGetUsageStatisticsMutation();

  const dispatch = useDispatch();

  useEffect(() => {
    getUsageStatistics().catch((error) => {
      console.log("Error fetching usage statistics:", error);
    });
  }, []);

  const storageUsageInGB = usageData?.storage.used / (1024 * 1024 * 1024) || 0;
  const storageLimitInGB = usageData?.storage.limit / (1024 * 1024 * 1024) || 0;
  const processingMinutesUsed =
    Math.floor(usageData?.processing_seconds.used / 60) || 0;
  const processingSecondsUsed =
    (usageData?.processing_seconds.used % 60).toFixed(0) || 0;
  const processingMinutesLimit =
    Math.floor(usageData?.processing_seconds.limit / 60) || 0;
  const uploadMinutesUsed =
    Math.floor(usageData?.upload_seconds.used / 60) || 0;
  const uploadSecondsUsed =
    (usageData?.upload_seconds.used % 60).toFixed(0) || 0;
  const uploadMinutesLimit =
    Math.floor(usageData?.upload_seconds.limit / 60) || 0;

  const handleClose = () => {
    config.onClose?.();
    dispatch(closeModal());
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <div className={classes.loader}>
          <Loader size="xl" />
        </div>
      );
    }

    return (
      <Grid gutter="md">
        <Col span={6} className={classes.ring}>
          <RingProgress
            label={
              <Text size="xs" ta="center">
                Storage
              </Text>
            }
            sections={[
              {
                value: (storageUsageInGB / storageLimitInGB) * 100,
                color: "#FFBB0F",
              },
            ]}
            size={120}
          />
          <Text>{`${storageUsageInGB.toFixed(
            2
          )} GB / ${storageLimitInGB} GB used`}</Text>
        </Col>
        <Col span={6} className={classes.ring}>
          <RingProgress
            label={
              <Text size="xs" ta="center">
                Processing minutes
              </Text>
            }
            sections={[
              {
                value:
                  (usageData?.processing_seconds.used /
                    usageData?.processing_seconds.limit) *
                    100 || 0,
                color: "#FFBB0F",
              },
            ]}
            size={120}
          />
          <Text>{`${processingMinutesUsed}m ${processingSecondsUsed}s / ${processingMinutesLimit}m used`}</Text>
        </Col>
        <Col span={6} className={classes.ring}>
          <RingProgress
            label={
              <Text size="xs" ta="center">
                Upload minutes
              </Text>
            }
            sections={[
              {
                value:
                  (usageData?.upload_seconds.used /
                    usageData?.upload_seconds.limit) *
                    100 || 0,
                color: "#FFBB0F",
              },
            ]}
            size={120}
          />
          <Text>{`${uploadMinutesUsed}m ${uploadSecondsUsed}s / ${uploadMinutesLimit}m used`}</Text>
        </Col>
      </Grid>
    );
  };

  return (
    <Modal
      classNames={{
        root: classes.root,
        title: classes.title,
        body: classes.body,
      }}
      opened={true}
      onClose={handleClose}
      padding="md"
      title="Usage Quota"
      centered={true}
      size="40%"
    >
      {renderContent()}
    </Modal>
  );
};

export default UsageQuotaModal;
