import React, { useRef, useEffect, useState, memo, useCallback } from "react";
import { TimelineZoomLevel } from "../../types";
import SegmentsEditor from "../segments-editor/SegmentsEditor";
import { IVideoSegment } from "../../remotion/types";

// Constants
const TIMELINE_HEIGHT = 25;
export const TIMELINE_PADDING = 10;

interface VideoTimelineProps {
  videos: IVideoSegment[] & { active: boolean };
  videoDuration: number;
  onItemResize: (updatedItems: IVideoSegment[], type: string) => void;
  canvasWidth: number;
  timelineZoomLevel: number;
  zoomLevels: TimelineZoomLevel[];
  onPixelPerSecondChange: (pixelsPerSecond: number) => void;
  scrollLeft: number;
  onTimeUpdated: (time: number) => void;
}

const VideoTimeline: React.FC<VideoTimelineProps> = memo(
  ({
    videos,
    videoDuration,
    onItemResize,
    canvasWidth,
    timelineZoomLevel,
    zoomLevels,
    onPixelPerSecondChange,
    scrollLeft,
    onTimeUpdated,
  }) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const hoverLineRef = useRef<HTMLDivElement>(null);
    const tooltipRef = useRef<HTMLDivElement>(null);
    const [zoomLevel, setZoomLevel] = useState(timelineZoomLevel);
    const [pixelsPerSecond, setPixelsPerSecond] = useState(0);
    const [offset, setOffset] = useState(0);

    const formatInterval = (
      seconds: number,
      precise: boolean = false
    ): string => {
      const formatWithPrecision = (value: number, unit: string): string => {
        if (precise) {
          const wholePart = Math.floor(value);
          const fractionalPart = Math.floor((value - wholePart) * 1000);
          return `${wholePart}${unit} ${fractionalPart
            .toString()
            .padStart(3, "0")}ms`;
        }
        return `${Math.floor(value)}${unit}`;
      };

      if (seconds >= 3600) {
        const hours = Math.floor(seconds / 3600);
        const remainingSeconds = seconds % 3600;
        const minutes = Math.floor(remainingSeconds / 60);
        const secs = remainingSeconds % 60;

        if (secs === 0 && !precise) {
          return minutes > 0 ? `${hours}h ${minutes}m` : `${hours}h`;
        } else {
          return `${hours}h ${minutes}m ${formatWithPrecision(secs, "s")}`;
        }
      } else if (seconds >= 60) {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;

        if (secs === 0 && !precise) {
          return `${minutes}m`;
        } else {
          return `${minutes}m ${formatWithPrecision(secs, "s")}`;
        }
      } else {
        return formatWithPrecision(seconds, "s");
      }
    };

    const drawTimeline = (
      zoomLevel: TimelineZoomLevel,
      canvasWidth: number
    ): void => {
      const numberOfIntervals = Math.ceil(videoDuration / zoomLevel.interval);

      const totalTimelineLength =
        (videoDuration / zoomLevel.interval) * zoomLevel.spaceBetweenIntervals;

      const currentVisualizedTimelineLength =
        (videoDuration * canvasWidth) / totalTimelineLength;

      const pixelsPerSecond: number =
        canvasWidth / currentVisualizedTimelineLength;

      setPixelsPerSecond(pixelsPerSecond);
      onPixelPerSecondChange(pixelsPerSecond);

      const canvas = canvasRef.current;

      if (!canvas) return;

      const ctx = canvas.getContext("2d");

      if (!ctx) return;

      // Clear the canvas
      ctx.clearRect(0, 0, canvasWidth, ctx.canvas.height);

      // Set styles for the text
      ctx.fillStyle = "#000"; // Black color for the text
      ctx.textBaseline = "middle";

      const offset = (scrollLeft * totalTimelineLength) / canvasWidth;

      // Draw the intervals and the dots
      for (let i = 0; i <= numberOfIntervals; i++) {
        const xPosition = i * zoomLevel.spaceBetweenIntervals - offset;
        const intervalInSeconds = i * zoomLevel.interval;

        setOffset(offset);

        // Draw the interval text
        const intervalText = formatInterval(intervalInSeconds);
        ctx.fillStyle = "#1D1D1D"; // Grey color for the interval text
        ctx.fillText(intervalText, xPosition, TIMELINE_HEIGHT / 2); // Draw at the center point of the interval

        // Draw dots between intervals
        if (i < numberOfIntervals) {
          // No dots after the last interval
          const dots = 4; // Number of dots between intervals
          const dotOffset = 1; // Offset from the interval marker
          const spaceBetweenDots =
            (zoomLevel.spaceBetweenIntervals - dotOffset * 2) / (dots + 1);
          ctx.fillStyle = "#ccc"; // Grey color for the dots
          for (let j = 1; j <= dots; j++) {
            const dotPosition = xPosition + dotOffset + j * spaceBetweenDots;
            ctx.beginPath();
            ctx.arc(dotPosition, TIMELINE_HEIGHT / 2, 1, 0, Math.PI * 2); // Small dot
            ctx.fill();
          }
        }
      }
    };

    // Effect hook for redrawing when videos, or zoom level change
    useEffect(() => {
      if (zoomLevels.length === 0 || zoomLevel === null) return;

      const selectedZoomLevel: TimelineZoomLevel = zoomLevels.find(
        (level: TimelineZoomLevel) => level.index === zoomLevel
      );

      drawTimeline(selectedZoomLevel, canvasWidth);
    }, [videos, zoomLevel, canvasWidth, scrollLeft, zoomLevels]);

    useEffect(() => {
      setZoomLevel(timelineZoomLevel);
    }, [timelineZoomLevel]);

    const onItemsUpdate = useCallback(
      (updatedVideos) => onItemResize(updatedVideos, "videos"),
      [onItemResize]
    );

    const timeUpdated = useCallback(
      (time: number) => {
        onTimeUpdated(time);
      },
      [onTimeUpdated]
    );

    // Hover functionality
    useEffect(() => {
      const container = containerRef.current;
      const hoverLine = hoverLineRef.current;
      const tooltip = tooltipRef.current;
      if (!container || !hoverLine || !tooltip) return;

      const onMouseMove = (e: MouseEvent) => {
        const rect = container.getBoundingClientRect();
        const mouseX = e.clientX - rect.left;

        // Update hover line position
        hoverLine.style.left = `${mouseX}px`;
        hoverLine.style.color = "#29b1a1";
        hoverLine.style.display = "block";

        // Calculate and update tooltip
        const timeAtPosition = (mouseX + offset) / pixelsPerSecond;
        const formattedTime = formatInterval(timeAtPosition, true);
        tooltip.textContent = formattedTime;
        tooltip.style.left = `${mouseX + 5}px`;
        tooltip.style.display = "block";
      };

      const onMouseLeave = () => {
        hoverLine.style.display = "none";
        tooltip.style.display = "none";
      };

      const throttledMouseMove = throttle(onMouseMove, 16); // ~60fps

      container.addEventListener("mousemove", throttledMouseMove);
      container.addEventListener("mouseleave", onMouseLeave);

      return () => {
        container.removeEventListener("mousemove", throttledMouseMove);
        container.removeEventListener("mouseleave", onMouseLeave);
      };
    }, [offset, pixelsPerSecond]);

    const handleTimelineClick = useCallback(
      (e: React.MouseEvent<HTMLDivElement>) => {
        const container = containerRef.current;
        if (!container) return;

        const rect = container.getBoundingClientRect();
        const clickX = e.clientX - rect.left;
        const timeAtPosition = (clickX + offset) / pixelsPerSecond;

        // Update the video time
        onTimeUpdated(timeAtPosition);

        // Keep the hover line and tooltip visible after click
        if (hoverLineRef.current && tooltipRef.current) {
          hoverLineRef.current.style.left = `${clickX}px`;
          hoverLineRef.current.style.display = "block";

          const formattedTime = formatInterval(timeAtPosition, true);
          tooltipRef.current.textContent = formattedTime;
          tooltipRef.current.style.left = `${clickX + 5}px`;
          tooltipRef.current.style.display = "block";
        }
      },
      [offset, pixelsPerSecond, onTimeUpdated]
    );

    return (
      <div
        ref={containerRef}
        style={{
          textAlign: "left",
          position: "relative",
        }}
        onClick={handleTimelineClick}
      >
        <canvas
          ref={canvasRef}
          width={canvasWidth}
          height={TIMELINE_HEIGHT}
          style={{ display: "block" }}
        />
        <div
          ref={hoverLineRef}
          style={{
            position: "absolute",
            top: 0,
            width: "2px",
            height: "100%",
            backgroundColor: "#29b1a1",
            display: "none",
            pointerEvents: "none",
          }}
        />
        <div
          ref={tooltipRef}
          style={{
            position: "absolute",
            top: `${TIMELINE_HEIGHT + 5}px`,
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            color: "white",
            padding: "2px 5px",
            borderRadius: "3px",
            fontSize: "12px",
            display: "none",
            pointerEvents: "none",
          }}
        />
        <div className="video-editor-canvas">
          <SegmentsEditor
            items={videos}
            canvasWidth={canvasWidth}
            pixelsPerSecond={pixelsPerSecond}
            videoDuration={videoDuration}
            type="video"
            scrollLeft={offset}
            onItemsUpdated={onItemsUpdate}
            onTimeUpdated={timeUpdated}
          />
        </div>
      </div>
    );
  }
);

// Throttle function
function throttle(func: (...args: any[]) => void, limit: number) {
  let inThrottle: boolean;
  return function (this: any, ...args: any[]) {
    const context = this;
    if (!inThrottle) {
      func.apply(context, args);
      inThrottle = true;
      setTimeout(() => (inThrottle = false), limit);
    }
  };
}

VideoTimeline.displayName = "VideoTimeline";

export default VideoTimeline;
