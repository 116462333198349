import { useToggle } from "@mantine/hooks";
import { useForm } from "@mantine/form";
import {
  TextInput,
  PasswordInput,
  Paper,
  PaperProps,
  Button,
  createStyles,
  Transition,
  Checkbox,
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";

import { FC, useRef, useState } from "react";
import envConfig from "../envConfig";
import { useDispatch } from "react-redux";
import { registerUser, userLogin } from "../features/auth/authActions";
import { ILoginPayload } from "../types";
import { fetchVideos } from "../features/videos/videosSlice";
import { PayloadAction } from "@reduxjs/toolkit";
import "./AuthenticationForm.scss";
import vidfastLogo from "./../assets/images/vidfast-logo.svg";
import VFIconComponent from "./icon/vf-icon";
import { useModal } from "../hooks/useModal";
import { ModalConfig } from "../features/modal/types";
import Turnstile, { useTurnstile } from "react-turnstile";

const useStyles = createStyles((theme) => ({
  input: {
    marginBottom: "15px",
  },
  checkboxInput: {
    input: {
      "&:checked": {
        backgroundColor: "#29B1A1",
        borderColor: "#29B1A1",
      },
    },
  },
}));

export const AuthenticationForm: FC = (props: PaperProps) => {
  const isLoginRoute = window.location.pathname === "/login";

  const [isLogin, toggle] = useToggle([isLoginRoute, !isLoginRoute]);
  const [isLoading, setIsLoading] = useState(false);
  const form = useForm({
    initialValues: {
      fullName: "",
      email: "",
      password: "",
      confirmPassword: "",
      captcha_token: "",
      marketing_consent: true,
    },

    validate: {
      fullName: (val) => {
        const nameRegex =
          /^[A-Za-z]+(?:[-'\s][A-Za-z]+)*\s[A-Za-z]+(?:[-'\s][A-Za-z]+)*$/;

        if (!val) return "Please enter your full name";

        if (val.length < 2 || val.length > 50)
          return "Name must be between 2 and 50 characters";

        return nameRegex.test(val) ? null : "Please enter a valid full name";
      },
      email: (val) => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        if (!val) return "Please enter your email";

        return emailRegex.test(val)
          ? null
          : "Please enter a valid email address";
      },
      password: (val) =>
        val.length <= 3
          ? "Password should include at least 4 characters"
          : null,
      confirmPassword: (val, values) =>
        val === values.password ? null : "Passwords must match",
    },
    validateInputOnChange: true,
  });
  const [transitionToggle, setTransitionToggle] = useState(true);

  const [turnstileBound, setTurnstileBound] = useState<any>(null);

  const turnstile = useTurnstile();

  const emailValidator = (val: string) => /^\S+@\S+$/.test(val);

  const passwordValidator = (val: string) => val.length > 3;

  const { classes } = useStyles();

  const dispatch = useDispatch();

  const title = isLogin ? "Log In to Vidfast" : "Sign Up in Vidfast";

  const buttonLabel = isLogin ? "Log In" : "Sign Up";

  const { openModal, closeModal } = useModal();

  const handleAuth = async (e: any) => {
    e.preventDefault();
    form.validate();

    if (!isFormValid()) return;

    setIsLoading(true);

    if (isLogin) {
      const loginData: ILoginPayload = {
        username: form.values.email,
        password: form.values.password,
      };

      const resultAction: PayloadAction<any> = await dispatch(
        userLogin(loginData)
      );

      if (userLogin.fulfilled.match(resultAction)) {
        await dispatch(fetchVideos());
      } else {
        showNotification({
          radius: "md",
          icon: <VFIconComponent type="error" backgroundColor="#FFFFFF" />,
          message: resultAction.payload.detail,
        });

        setIsLoading(false);
      }
    } else {
      // password matcher check
      if (form.values.confirmPassword !== form.values.password) {
        showNotification({
          radius: "md",
          icon: <VFIconComponent type="error" backgroundColor="#FFFFFF" />,
          message: "The passwords must match",
        });

        setIsLoading(false);

        return 0;
      }

      turnstileBound?.execute();
    }
  };

  const isFormValid = (): boolean => {
    if (isLogin) {
      return (
        emailValidator(form.values.email) &&
        passwordValidator(form.values.password)
      );
    } else {
      return form.isValid();
    }
  };

  return (
    <>
      <div className="form-holder">
        <Paper
          style={{ zIndex: 6, backgroundColor: "#29B1A11F" }}
          radius="md"
          p="xl"
          shadow={"md"}
          withBorder
          {...props}
        >
          <Transition
            mounted={transitionToggle}
            transition="pop"
            duration={300}
            timingFunction="ease"
          >
            {(styles) => (
              <div style={styles}>
                <img
                  className="vidfast-logo"
                  width={50}
                  src={vidfastLogo}
                  alt="Logo"
                />

                <h1 className="form-title">{title}</h1>

                <form
                  className="authentication-form"
                  onSubmit={form.onSubmit(() => {})}
                >
                  <div className="inputs-holder">
                    {!isLogin && (
                      <TextInput
                        className={classes.input}
                        required
                        label="Full Name"
                        type={"text"}
                        placeholder="John Doe"
                        value={form.values.fullName}
                        onChange={(event) =>
                          form.setFieldValue(
                            "fullName",
                            event.currentTarget.value
                          )
                        }
                        error={form.errors.fullName}
                      />
                    )}

                    <TextInput
                      className={classes.input}
                      required
                      label="Email"
                      type={"email"}
                      placeholder="hello@vidfast.ai"
                      value={form.values.email}
                      onChange={(event) =>
                        form.setFieldValue("email", event.currentTarget.value)
                      }
                      error={form.errors.email}
                    />

                    <PasswordInput
                      className={classes.input}
                      required
                      label="Password"
                      placeholder="Your password"
                      value={form.values.password}
                      onChange={(event) =>
                        form.setFieldValue(
                          "password",
                          event.currentTarget.value
                        )
                      }
                      error={form.errors.password}
                    />

                    {!isLogin && (
                      <>
                        <PasswordInput
                          className={classes.input}
                          required
                          label="Confirm Password"
                          placeholder="Confirm your password"
                          value={form.values.confirmPassword}
                          onChange={(event) =>
                            form.setFieldValue(
                              "confirmPassword",
                              event.currentTarget.value
                            )
                          }
                          error={form.errors.confirmPassword}
                        />

                        <Checkbox
                          className={classes.checkboxInput}
                          label="I agree that my email will be used for marketing purposes."
                          placeholder="John Doe"
                          checked={form.values.marketing_consent}
                          onChange={(event) => {
                            form.setFieldValue(
                              "marketing_consent",
                              event.currentTarget.checked
                            );
                          }}
                        />

                        <div style={{ textAlign: "center" }}>
                          <Turnstile
                            sitekey={envConfig.captchaSiteKey}
                            execution="execute"
                            onLoad={(widgetId, bound) => {
                              setTurnstileBound(bound);
                            }}
                            onVerify={async (token) => {
                              console.warn("Turnstile token", token);
                              form.setFieldValue("captcha_token", token);

                              const registerData = {
                                email: form.values.email,
                                password: form.values.password,
                                full_name: form.values.fullName,
                                marketing_consent:
                                  form.values.marketing_consent,
                                captcha_token: token,
                              };

                              const resultAction: PayloadAction<any> =
                                await dispatch(registerUser(registerData));

                              if (registerUser.fulfilled.match(resultAction)) {
                                await dispatch(fetchVideos());

                                let modalConfig: ModalConfig = {
                                  title: "Your Free Trial Starts Now!",
                                  iconConfig: { type: "rocket" },
                                  subtitle:
                                    "Transform your ideas into stunning videos. Your free trial is active!",
                                  closable: true,
                                  actions: [
                                    {
                                      label: "Let's Go!",
                                      onClick: () => {
                                        dispatch(closeModal());
                                      },
                                      variant: "filled",
                                      size: "lg",
                                    },
                                  ],
                                };

                                dispatch(openModal(modalConfig));
                              } else {
                                showNotification({
                                  radius: "md",
                                  icon: (
                                    <VFIconComponent
                                      type="error"
                                      backgroundColor="#FFFFFF"
                                    />
                                  ),
                                  message: resultAction.payload.detail,
                                });

                                setIsLoading(false);
                              }
                            }}
                          />
                        </div>
                      </>
                    )}
                  </div>
                  <div className="actions-holder">
                    <Button
                      className="vf-button-filled"
                      onClick={handleAuth}
                      type="submit"
                      loading={isLoading}
                    >
                      {buttonLabel}
                    </Button>
                    <span
                      className="form-toggle"
                      onClick={() => {
                        form.clearErrors();

                        setTransitionToggle(false);

                        setTimeout(() => {
                          toggle();
                        }, 180);

                        setTimeout(() => {
                          setTransitionToggle(true);
                        }, 250);
                      }}
                    >
                      {!isLogin ? (
                        <>
                          Already have an account?{" "}
                          <span className="toggle-cta">Click to log in!</span>
                        </>
                      ) : (
                        <>
                          Don't have an account?{" "}
                          <span className="toggle-cta">Click to sign up!</span>
                        </>
                      )}
                    </span>
                  </div>
                </form>
              </div>
            )}
          </Transition>
        </Paper>
      </div>
    </>
  );
};
