import { IconComponentProps } from "./types";

export default function VideoFileIcon({
  color = "#FFFFFF",
  size = 30,
}: IconComponentProps) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 496 496"
      width={size}
      height={size}
    >
      <path
        fill="#6D6D6D"
        d="M484.8,48H287.2c-6.4,0-12,0-18.4,9.6L258.4,72H24.8C11.2,72,0,84.8,0,98.4v314.4
	C0,426.4,11.2,440,24.8,440h446.4c13.6,0,24.8-13.6,24.8-27.2V169.6V99.2V57.6C496,52,491.2,48,484.8,48z"
      />
      <path
        fill="#565656"
        d="M485.6,371.2c6.4-4.8,10.4-12,10.4-20V169.6V99.2V57.6c0-5.6-4.8-9.6-11.2-9.6H287.2
	c-6.4,0-12,0-18.4,9.6L258.4,72H24.8C12,72,1.6,82.4,0,94.4L485.6,371.2z"
      />
      <path
        fill="#262523"
        d="M16.8,104l468.8,265.6c6.4-4.8,10.4-10.4,10.4-18.4V169.6v-44.8L465.6,104H16.8z"
      />
      <rect x="40" y="104" fill="#FFFFFF" width="416" height="304" />
      <rect x="256" y="104" fill="#EFEFEF" width="200" height="304" />
      <path
        fill="#09CEBB"
        d="M496,424c0,13.6-11.2,24-24.8,24H24.8C11.2,448,0,437.6,0,424l8-253.6C8,156.8,19.2,144,32.8,144H464
	c13.6,0,24.8,12.8,24.8,26.4L496,424z"
      />
      <path
        fill="#0EE0B8"
        d="M492.8,436L9.6,161.6C8,164.8,8,168.8,8,172L0,424c0,13.6,11.2,24,24.8,24h446.4
	C480,448,488,443.2,492.8,436z"
      />
      <path
        fill="#09CEBB"
        d="M197.6,414.4c-1.6,0-4-0.8-6.4-1.6c-4-1.6-7.2-6.4-7.2-10.4V252.8c0-4,3.2-8.8,7.2-10.4
	c4-1.6,8.8-1.6,12.8,0.8l101.6,75.2c3.2,2.4,4.8,5.6,4.8,9.6s-1.6,7.2-4.8,9.6L204,412.8C202.4,413.6,200,414.4,197.6,414.4z"
      />
      <path
        fill="#FFFFFF"
        d="M197.6,385.6c-1.6,0-4-0.8-6.4-1.6c-4-1.6-7.2-5.6-7.2-10.4V224c0-4,3.2-8.8,7.2-10.4
	c4-1.6,8.8-1.6,12.8,0.8l101.6,75.2c3.2,2.4,4.8,5.6,4.8,9.6s-1.6,7.2-4.8,9.6L204,384C202.4,385.6,200,385.6,197.6,385.6z"
      />
    </svg>
  );
}
