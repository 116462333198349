import { IconComponentProps } from "./types";

export default function LockerIcon({
  color = "#9f4c4c",
  size = 30,
}: IconComponentProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="-10 0 60 60"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <style></style>
      </defs>
      <path
        fillRule="evenodd"
        fill={color}
        d="M644,356h32a4,4,0,0,1,4,4v26a4,4,0,0,1-4,4H644a4,4,0,0,1-4-4V360A4,4,0,0,1,644,356Zm18,17.445V378a2,2,0,0,1-4,0v-4.555A4,4,0,1,1,662,373.445ZM670,352v-6a10,10,0,0,0-20,0v6h-6v-6a16,16,0,0,1,32,0v6h-6Z"
        transform="translate(-640 -330)"
      />
    </svg>
  );
}
