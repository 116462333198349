import React from "react";
import { SegmentComp } from "./Segment";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";
import { useRemotionContext } from "../RemotionContext";
import { findSubtitle } from "../../utils";
import { useCurrentFrame, useVideoConfig } from "remotion";

export const Subtitles: React.FC<{}> = ({}) => {
  const { scale, subtitles, configUpdate } = useRemotionContext();
  const frame = useCurrentFrame();
  const { fps } = useVideoConfig();
  const timeInSeconds = frame / fps;

  const subtitlesDragHandler = (e: DraggableEvent, position: DraggableData) => {
    configUpdate({
      subtitles: {
        ...subtitles,
        position: { x: position.x, y: position.y },
      },
    });
  };

  const subtitlesDragStartHandler = (e: DraggableEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const currentSegment = findSubtitle(subtitles.items, timeInSeconds);

  return (
    <Draggable
      scale={scale}
      axis="y"
      defaultPosition={{ x: 0, y: subtitles.position?.y ?? 0 }}
      bounds={"parent"}
      onStop={subtitlesDragHandler}
      onStart={subtitlesDragStartHandler}
    >
      <div
        style={{
          width: "100%",
          zIndex: 2000,
          position: "absolute",
        }}
      >
        {currentSegment && <SegmentComp segment={currentSegment} />}
      </div>
    </Draggable>
  );
};
