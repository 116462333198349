import { IconComponentProps } from "./types";

export default function UploadIcon2({
  color = "#9f4c4c",
  size = 30,
}: IconComponentProps) {
  return (
    <svg
      height={size}
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        fill="#F2F2F2"
        d="M256,0C114.616,0,0,114.616,0,256s114.616,256,256,256s256-114.616,256-256S397.384,0,256,0z"
      />
      <polygon
        points="216.128,155.104 243.232,127.472 243.232,194.16 268.52,194.16 268.52,127.472 295.624,155.104 313.672,137.504 
	255.872,78.576 198.08,137.504 "
      />
      <path
        fill="#E21B1B"
        d="M411.744,369.408H100c-8.872,0-16.128-7.256-16.128-16.128v-95.792
	c0-8.872,7.256-16.128,16.128-16.128h311.744c8.872,0,16.128,7.256,16.128,16.128v95.792
	C427.872,362.152,420.616,369.408,411.744,369.408z"
      />
      <g>
        <path
          fill="#FFFFFF"
          d="M125.264,277.76v31.16c0,9.312,3.536,14.056,9.8,14.056c6.424,0,9.96-4.496,9.96-14.056v-31.16
		h12.208v30.352c0,16.704-8.432,24.656-22.568,24.656c-13.648,0-21.68-7.552-21.68-24.816V277.76H125.264z"
        />
        <path
          fill="#FFFFFF"
          d="M165.84,278.48c3.776-0.64,9.072-1.12,16.544-1.12c7.552,0,12.928,1.448,16.544,4.336
		c3.456,2.728,5.784,7.224,5.784,12.528c0,5.296-1.76,9.8-4.976,12.848c-4.176,3.936-10.36,5.704-17.584,5.704
		c-1.608,0-3.048-0.08-4.176-0.24v19.352H165.84V278.48z M177.968,303.056c1.048,0.24,2.328,0.32,4.104,0.32
		c6.504,0,10.512-3.288,10.512-8.832c0-4.976-3.448-7.952-9.552-7.952c-2.488,0-4.176,0.24-5.064,0.48V303.056z"
        />
        <path
          fill="#FFFFFF"
          d="M210.512,277.76H222.8v43.848h21.528v10.28H210.52V277.76H210.512z"
        />
        <path
          fill="#FFFFFF"
          d="M296.048,304.264c0,17.744-10.76,28.504-26.584,28.504c-16.056,0-25.456-12.128-25.456-27.544
		c0-16.224,10.36-28.352,26.344-28.352C286.968,276.872,296.048,289.32,296.048,304.264z M256.936,304.984
		c0,10.6,4.984,18.064,13.168,18.064c8.272,0,13.008-7.872,13.008-18.384c0-9.72-4.656-18.072-13.088-18.072
		C261.752,286.592,256.936,294.464,256.936,304.984z"
        />
        <path
          fill="#FFFFFF"
          d="M313.408,317.992l-3.856,13.896h-12.688l16.544-54.128h16.064l16.784,54.128h-13.168l-4.176-13.896
		H313.408z M327.144,308.84l-3.368-11.488c-0.968-3.216-1.928-7.232-2.736-10.44h-0.16c-0.8,3.208-1.608,7.304-2.488,10.44
		l-3.216,11.488H327.144z"
        />
        <path
          fill="#FFFFFF"
          d="M351.496,278.48c4.496-0.72,10.36-1.12,16.544-1.12c10.28,0,16.944,1.848,22.168,5.784
		c5.616,4.176,9.152,10.84,9.152,20.392c0,10.36-3.776,17.512-9,21.92c-5.704,4.744-14.376,6.984-24.976,6.984
		c-6.344,0-10.84-0.4-13.888-0.8V278.48z M363.776,322.648c1.048,0.24,2.728,0.24,4.256,0.24c11.08,0.08,18.304-6.024,18.304-18.952
		c0.088-11.24-6.504-17.184-17.024-17.184c-2.736,0-4.496,0.24-5.544,0.48v35.416H363.776z"
        />
      </g>
      <g style={{ opacity: 0.2 }}>
        <path
          fill="#FFFFFF"
          d="M100,241.36c-8.872,0-16.128,7.256-16.128,16.128v95.792c0,8.872,7.256,16.128,16.128,16.128h27.928
		L280.072,241.36H100z"
        />
      </g>
    </svg>
  );
}
