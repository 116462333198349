import { IconComponentProps } from "./types";

export default function WarningIcon({
  color = "#bf873e",
  size = 30,
}: IconComponentProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 -4.02 60.031 60.031"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M214.413,746a4.455,4.455,0,0,1-3.84-2.166,4.249,4.249,0,0,1,0-4.334l25.572-43.331a4.483,4.483,0,0,1,7.679,0L269.4,739.5a4.249,4.249,0,0,1,0,4.334,4.452,4.452,0,0,1-3.84,2.166H214.413ZM240,706a4,4,0,0,0-4,4v16a4,4,0,0,0,8,0V710A4,4,0,0,0,240,706Zm0,36a4,4,0,1,0-4-4A4,4,0,0,0,240,742Z"
        id="attention"
        transform="translate(-209.969 -694)"
      />
    </svg>
  );
}
