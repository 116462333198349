import { LoadingOverlay } from "@mantine/core";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Projects.scss";
import { useDispatch, useSelector } from "react-redux";
import { AddNewVideoModal } from "../../components/AddNewVideoModal";
import NewVideoCard from "../../components/NewVideoCard";
import SourceVideoCard from "../../components/SourceVideoCard";
import { VIDEO_ACTIONS_MAP } from "../../constants";
import { resetClipConfig } from "../../features/clipConfig/clipConfigSlice";
import { selectVideos } from "../../features/videos/videosSlice";
import { RootState } from "../../store";
import { IVideo } from "../../types";
import { formatEntityTitle } from "../../utils";

export const Projects: FC = () => {
  const navigate = useNavigate(),
    [newVideoModalOpened, setNewVideoModalOpened] = useState(false);

  const dispatch = useDispatch();

  const [isVideoUploading, setIsVideoUploading] = useState(false);

  useEffect(() => {
    dispatch(resetClipConfig());
  }, [dispatch]);

  let content;

  const videos: IVideo[] = useSelector(selectVideos);
  const videosStatus = useSelector(
    (state: RootState) => state.videos.loadingStatus
  );
  const error = useSelector((state: any) => state.videos.error);

  const handleVideoAction = (
    actionType: string,
    videoId: string,
    projectTitle: string
  ) => {
    if (!actionType) return;

    switch (actionType) {
      case VIDEO_ACTIONS_MAP.VIDEO_EDITS:
        navigate(`./${videoId}/clips`, { state: { projectTitle } });
        break;
      case VIDEO_ACTIONS_MAP.NEW_EDIT:
        navigate(`./${videoId}/edit`, { state: { projectTitle } });

        break;
      case VIDEO_ACTIONS_MAP.VIDEO_DETAILS:
        navigate(`./${videoId}`, { state: { projectTitle } });
        break;
      default:
    }
  };

  const buttons = [
    {
      action: VIDEO_ACTIONS_MAP.NEW_EDIT,
      title: "New Clip",
    },
    {
      action: VIDEO_ACTIONS_MAP.VIDEO_DETAILS,
      title: "Details",
    },
    {
      action: VIDEO_ACTIONS_MAP.VIDEO_EDITS,
      title: "View clips",
    },
  ];

  if (videosStatus === "loading") {
    content = (
      <LoadingOverlay
        visible={true}
        loaderProps={{ size: "lg", variant: "dots" }}
      />
    );
  } else if (videosStatus === "succeeded") {
    content = videos.map((video: any) => {
      let projectTitle: string = formatEntityTitle(video.title);

      return (
        <SourceVideoCard
          entityType="video"
          key={video.id}
          thumbnailUrl={video.thumbnail_path}
          title={projectTitle}
          id={video.id}
          handleAction={handleVideoAction}
          buttons={buttons}
          renderingStatus={video.processing_status}
        />
      );
    });

    content.unshift(
      <NewVideoCard
        entityType="project"
        key={"new-video-card"}
        handleAddNewVideo={() => setNewVideoModalOpened(true)}
      />
    );
  } else if (videosStatus === "failed") {
    content = <div>{error.toString()}</div>;
  }

  return (
    <div className="projects-page-holder">
      <AddNewVideoModal
        isOpen={newVideoModalOpened}
        setIsOpen={setNewVideoModalOpened}
      />
      <div className="video-cards-holder">{content}</div>
    </div>
  );
};
