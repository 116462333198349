import { createSlice } from "@reduxjs/toolkit";
import { ISubtitles, IVideoSegment } from "../../remotion/types";
import { RootState } from "../../store";
import { SUBTITLE_TEMPLATES } from "../../constants";
// import { deepMerge } from "../../utils";

interface ClipConfigState {
  segments: IVideoSegment[] | null;
  subtitles: ISubtitles;
}

const initialState: ClipConfigState = {
  subtitles: {
    config: SUBTITLE_TEMPLATES[0],
    items: null,
    position: { x: 0, y: 0 },
  },
  segments: null,
};

function isObject(item: any) {
  return item && typeof item === "object" && !Array.isArray(item);
}

function deepMerge(target: any, ...sources: any[]): any {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        deepMerge(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }
  return deepMerge(target, ...sources);
}

export const clipConfigSlice = createSlice({
  name: "clipConfig",
  initialState,
  reducers: {
    setSubtitles: (state, action) => {
      const newSubs = deepMerge(state.subtitles, action.payload);

      state.subtitles = newSubs;
    },
    setSegments: (state, action) => {
      state.segments = action.payload;
    },
    resetClipConfig: (state) => {
      state.subtitles = initialState.subtitles;
      state.segments = initialState.segments;
    },
  },
});

export const selectSubtitles = (state: RootState) => state.clipConfig.subtitles;

export const selectSegments = (state: RootState) => state.clipConfig.segments;

export const { setSubtitles, setSegments, resetClipConfig } =
  clipConfigSlice.actions;

export default clipConfigSlice.reducer;
