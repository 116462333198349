export interface Config {
  baseApi: string;
  googleAnalyticsId: string | null;
  googleTagManagerId: string | null;
  hotjarId: number | null;
  clarityId: string | null;
  captchaSiteKey: string;
  stripePricingTableId: string;
  stripePublishableKey: string;
}

const loadConfig = () => {
  // validate all required env variables are set
  let required_variables = [
    "VITE_BASE_API",
    "VITE_TURNSTILE_SITE_KEY",
    "VITE_STRIPE_PRICING_TABLE_ID",
    "VITE_STRIPE_PUBLISHABLE_KEY",
  ];
  required_variables.forEach((variable) => {
    if (!import.meta.env[variable]) {
      throw new Error(`Missing required environment variable ${variable}`);
    }
  });

  const config: Config = {
    baseApi: import.meta.env.VITE_BASE_API,
    googleAnalyticsId: import.meta.env.VITE_GOOGLE_ANALYTICS_ID,
    googleTagManagerId: import.meta.env.VITE_GOOGLE_TAG_MANAGER_ID,
    hotjarId: +import.meta.env.VITE_HOTJAR_ID,
    clarityId: import.meta.env.VITE_CLARITY_ID,
    captchaSiteKey: import.meta.env.VITE_TURNSTILE_SITE_KEY,
    stripePricingTableId: import.meta.env.VITE_STRIPE_PRICING_TABLE_ID,
    stripePublishableKey: import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY,
  };

  return config;
};

const envConfig = loadConfig();

export default envConfig;
