import React, { useImperativeHandle, useRef } from "react";
import { useCurrentFrame, useVideoConfig } from "remotion";
import { ISubtitleWord } from "../types";
import { useRemotionContext } from "../RemotionContext";

const WordComp: React.ForwardRefRenderFunction<
  HTMLSpanElement,
  {
    word: ISubtitleWord;
    index: number;
  }
> = ({ word }, ref) => {
  const { subtitles } = useRemotionContext();
  const frame = useCurrentFrame();
  const { fps } = useVideoConfig();
  const timeInSeconds = frame / fps;

  const componentRef = useRef<HTMLSpanElement>(null);
  useImperativeHandle(ref, () => componentRef.current as HTMLSpanElement);

  const isShown = timeInSeconds >= word.start && timeInSeconds <= word.end;

  return (
    <span
      ref={componentRef}
      style={{
        borderRadius: "15px",
        backgroundColor: isShown
          ? subtitles.config.highlightBackgroundColor
          : "transparent",
        color: isShown
          ? subtitles.config.highlightColor
          : subtitles.config.color,
        display: "inline-block",
        justifyContent: "center",
        paddingLeft: "10px",
        paddingRight: "10px",
        fontFamily: subtitles.config.font,
      }}
    >
      {word.word}
    </span>
  );
};

export const WordComponent = React.forwardRef(WordComp);
