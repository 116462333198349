import { AuthenticationForm } from "../components/AuthenticationForm";
import { FC, useEffect } from "react";
import "./AuthenticationPage.scss";
import { useNavigate } from "react-router-dom";
import { RootState } from "../store";
import { useSelector } from "react-redux";

export const AuthenticationPage: FC = () => {
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated]);

  return (
    <div className="page-holder">
      <AuthenticationForm />
    </div>
  );
};
