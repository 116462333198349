import { useState } from "react";
import {
  TextInput,
  Button,
  Col,
  Modal,
  Grid,
  createStyles,
} from "@mantine/core";
import { useChangePasswordMutation } from "../features/api/apiSlice";
import { showNotification } from "@mantine/notifications";
import { handleErrors } from "../utils";
import VFIconComponent from "./icon/vf-icon";
import { ModalConfig } from "../features/modal/types";
import { closeModal } from "../features/modal/modalSlice";
import { useDispatch } from "react-redux";

interface PasswordChangeFormProps {
  config: ModalConfig;
}

const useStyles = createStyles((theme) => ({
  title: {
    fontWeight: "bold",
    fontSize: "24px",
    textAlign: "center",
    margin: "0 auto",
  },
  body: {
    padding: "40px",
  },
  root: {
    textAlign: "center",
    marginTop: 35,
    minHeight: 100,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
}));

const PasswordChangeForm: React.FC<PasswordChangeFormProps> = ({ config }) => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [changePassword, { isLoading }] = useChangePasswordMutation();
  const { classes, theme } = useStyles();
  const dispatch = useDispatch();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      await changePassword({
        old_password: oldPassword,
        new_password: newPassword,
      }).unwrap();

      showNotification({
        radius: "md",
        title: "Success",
        message: "Password changed successfully.",
        icon: <VFIconComponent type="success" backgroundColor="#FFFFFF" />,
      });

      handleClose();
    } catch (error) {
      handleErrors(error);
    }
  };

  const handleClose = () => {
    config.onClose?.();
    dispatch(closeModal());
  };

  return (
    <Modal
      classNames={{
        root: classes.root,
        title: classes.title,
        body: classes.body,
      }}
      opened={true}
      onClose={handleClose}
      padding={"lg"}
      title="Change Password"
      centered={true}
      overlayOpacity={0.55}
      overlayBlur={3}
      size={"md"}
      closeOnClickOutside={true}
      closeOnEscape={false}
      withCloseButton={true}
    >
      <Grid gutter="md">
        <Col span={12}>
          <form onSubmit={handleSubmit}>
            <TextInput
              label="Old Password"
              type="password"
              required
              value={oldPassword}
              onChange={(event) => setOldPassword(event.currentTarget.value)}
              style={{ marginBottom: "20px" }}
            />
            <TextInput
              label="New Password"
              type="password"
              required
              value={newPassword}
              onChange={(event) => setNewPassword(event.currentTarget.value)}
              style={{ marginBottom: "40px" }}
            />
            <Button
              className="vf-button-filled"
              type="submit"
              color="primary"
              fullWidth
              loading={isLoading}
            >
              Change Password
            </Button>
          </form>
        </Col>
      </Grid>
    </Modal>
  );
};

export default PasswordChangeForm;
