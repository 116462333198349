import React, { useState } from "react";
import { useCurrentFrame, useVideoConfig } from "remotion";
import { WordComponent } from "./Word";
import { ISubtitle } from "../types";
import { useRemotionContext } from "../RemotionContext";

const InnerComponent: React.FC<{
  segment: ISubtitle;
}> = ({ segment }) => {
  const { scale, videoRatio, subtitles } = useRemotionContext();

  const [wordRefs] = useState(() => {
    return new Array(segment.words.length)
      .fill(0)
      .map(() => React.createRef<HTMLSpanElement>());
  });

  return (
    <div
      style={{
        textAlign: "center", // This thing helps with center bottom top option
      }}
    >
      <div
        style={{
          cursor: "pointer",
          fontSize: (subtitles.config.size * videoRatio) / scale,
          fontFamily: subtitles.config.font,
          ...(subtitles.config.stroke && {
            textShadow: `${(3 * videoRatio) / scale}px ${
              (3 * videoRatio) / scale
            }px ${(10 * videoRatio) / scale}px ${subtitles.config.strokeColor}`,
            WebkitTextStroke: `${(3 * videoRatio) / scale}px ${
              subtitles.config.strokeColor
            }`,
          }),
        }}
      >
        {segment.words.map((word, i) => {
          return (
            <WordComponent ref={wordRefs[i]} key={i} index={i} word={word} />
          );
        })}
      </div>
    </div>
  );
};

export const SegmentComp: React.FC<{
  segment: ISubtitle;
}> = ({ segment }) => {
  const frame = useCurrentFrame();
  const { fps } = useVideoConfig();
  const timeInSeconds = frame / fps;
  const { start, end } = segment;

  if (timeInSeconds < start || timeInSeconds > end) {
    return null;
  }

  return <InnerComponent segment={segment} />;
};
