import { Button } from "@mantine/core";
import "./NewVideoCard.scss";
import VFIconComponent from "./icon/vf-icon";

export default function NewVideoCard(props: {
  handleAddNewVideo: Function;
  entityType: string;
}) {
  const cardTitle = `Add New ${
    props.entityType === "clip" ? "Clip" : "Project"
  }`;

  const cardDescription =
    props.entityType === "project"
      ? "Click to upload a video"
      : "Click to create a new clip";

  return (
    <div
      className="new-video-card"
      onClick={() => {
        props.handleAddNewVideo();
      }}
    >
      <VFIconComponent className="add-video-icon" type="add-video" size={64} />
      <p className="primary-text">{cardTitle}</p>
      <p className="secondary-text">{cardDescription}</p>
    </div>
  );
}
