import "./CaptionsSeparator.scss";
import mergeCaptionsIcon from "./../../assets/icons/merge-captions.svg";
import AddNewIcon from "../icons/AddNewIcon";

interface CaptionsSeparatorProps {
  handleCaptionAction: (action: string) => void;
}

export default function CaptionsSeparator({
  handleCaptionAction,
}: CaptionsSeparatorProps) {
  return (
    <div className="captions-separator-holder">
      <div className="separator"></div>
      <div className="actions">
        {/* <div
          className="icon-wrapper"
          onClick={() => handleCaptionAction("add")}
        >
          <AddNewIcon fill={"black"} />
        </div> */}
        <div
          className="icon-wrapper"
          onClick={() => handleCaptionAction("merge")}
        >
          <img
            className="merge-captions"
            src={mergeCaptionsIcon}
            alt="Merge Subtitles"
          />
        </div>
      </div>
    </div>
  );
}
