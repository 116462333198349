import {
  ISubtitle,
  ISubtitles,
  ISubtitlesConfig,
  IVideoSegment,
} from "./remotion/types";

export interface IvideoFile {
  video: File;
}

export interface IVideo {
  subtitles?: ISubtitle[];
  created_at: string;
  file_path: string;
  media_metadata: IVideoMetadata;
  thumbnail_path: string;
  processing_status: ProcessingStatus;
  title: string;
  updated_at: string;
  id: string;
}

export interface IVideoClip {
  created_at: string;
  file_path: string;
  media_metadata: IVideoMetadata;
  thumbnail_path: string;
  rendering_status: ProcessingStatus;
  rendering_error: any;
  title: string;
  updated_at: string;
  video_id: string;
  id: string;
}

export interface IVideoMetadata {
  duration_seconds: number;
  file_size_bytes: number;
  height: number;
  width: number;
  fps: number;
}

export interface IResponseMeEndpoint {
  email: string;
  id: string;
}

export interface IVideoPayload {
  source_type: string;
  file?: any;
  url?: string;
}

export interface ILoginPayload {
  grant_type?: string;
  username: string;
  password: string;
  scope?: string;
  client_id?: string;
  client_secret?: string;
}

export interface IUserData {
  id: string;
  created_at: string;
  updated_at: string;
  email: string;
  roles: string[];
  email_confirmed: boolean;
  stripe_customer_id: string;
  subscription_level: number;
  monthly_processing_seconds_used: number;
  monthly_video_upload_seconds_used: number;
}
export interface IRegisterPayload {
  email: string;
  password: string;
  captcha_token: string;
}
export interface IToolConfig {
  enabled: boolean;
  key: string;
  title: string;
  showFor: "project" | "edit" | "both";
  options?: IToolOption[] | null;
  tabs?: IToolTab[] | null;
}

export interface IToolOption {
  key: string;
  type: "color-picker" | "number" | "select" | "text" | "radio-group";
  items?: string[] | IRadioInput[] | null;
  label: string;
  defaultValue: string | number;
  minValue?: number;
  step?: number;
  value?: string | number | null;
  description?: string;
  placeholder: string;
  showFor: "project" | "edit" | "both";
}

export interface IToolTab {
  title: string;
  options: IToolOption[] | ICaption[];
  data?: any;
  showFor: "project" | "edit" | "both";
}

export interface ICreateClipPayload {
  title: string;
  segments: IVideoSegment[];
  subtitles?: ISubtitles;
}

export interface ICaptionsConfig {
  size: number;
  font: string;
  color: string;
  stroke_color: string;
  stroke_width: number;
  position: "top" | "center" | "bottom";
  words_per_line?: number; // not available when editing cut
}

export interface IEditCutPayload {
  captions: ICaption[];
  captions_config: {
    size?: number;
    font: string;
    color: string;
    stroke_color: string;
    stroke_width: number;
    position: string;
  };
}

export interface ICaption {
  start: number;
  end: number;
  text: string;
}

export interface IRadioInput {
  value: string;
  label: string;
}

export interface IClipSizeOption {
  icon: string;
  id: number;
  label: string;
  value: string;
  size: string;
  description?: string;
}

export enum ProcessingStatus {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
  PROCESSING = "PROCESSING",
}

export enum SSEEventTypes {
  VIDEO_PROCESSED = "video.processed",
  CLIP_PROCESSED = "clip.processed",
  ERROR = "error",
  SUBSCRIPTION_UPGRADED = "subscription.upgraded",
}

export interface ClipProcessedEventData {
  id: string;
  created_at: string;
  updated_at: string | null;
  title: string;
  file_path: string;
  rendering_status: string;
  video_id: string;
  media_metadata: IVideoMetadata;
  thumbnail_path: string;
  subtitles: ISubtitle[];
  segments: IVideoSegment[];
}

export interface TimelineZoomLevel {
  index: number;
  interval: number; // Interval between markers on the timeline
  spaceBetweenIntervals: number; // Space between intervals on the timeline in pixels
  default: boolean;
}

export enum ErrorResponseDetailType {
  TRIAL_EXPIRED = "trial_expired",
  QUOTA_EXCEEDED_STORAGE = "quota_exceeded.storage",
  QUOTA_EXCEEDED_UPLOAD = "quota_exceeded.upload",
  QUOTA_EXCEEDED_EXPORT = "quota_exceeded.export",
  NO_SUBSCRIPTION = "no_subscription",
}

export interface ErrorResponseDetail {
  msg: string;
  type: ErrorResponseDetailType;
}

export interface ErrorResponse {
  detail: ErrorResponseDetail[] | string;
}
