import { FC } from "react";
import {
  useDeleteClipMutation,
  useGetClipQuery,
} from "../features/api/apiSlice";
import "././ProjectDetails/ProjectDetails.scss";
import { detailsPageFactory } from "./detailsPageFactory";

const config = {
  entityIdName: "editId",
  deleteMutation: () => {
    return useDeleteClipMutation();
  },
  getEntityQuery: (id: string) => {
    return useGetClipQuery(id);
  },
  entityRepresentation: "Clip",
  extra_buttons: [],
};

export const EditDetails: FC = detailsPageFactory(config);
