import { IVideoSegment } from "./types";

export const getCurrentSegment = (
  currentTimeInSeconds: number,
  segments: IVideoSegment[]
): IVideoSegment | null => {
  const TOLERANCE = 0.04;

  return (
    segments.find(
      (seg) =>
        currentTimeInSeconds >= seg.start - TOLERANCE &&
        currentTimeInSeconds <= seg.end + TOLERANCE
    ) || null
  );
};
