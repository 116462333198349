import { FC } from "react";
import "./ProjectDetails.scss";
import { detailsPageFactory } from "../detailsPageFactory";
import { useSelector } from "react-redux";
import {
  deleteVideo,
  selectVideoById,
} from "../../features/videos/videosSlice";
import { RootState } from "../../store";

const config = {
  entityIdName: "projectId",
  deleteMutation: (id) => {
    return deleteVideo(id);
  },
  getEntityQuery: (projectId) => {
    const video = useSelector((state: RootState) => {
      return selectVideoById(state, projectId);
    });

    return {
      data: video,
    };
  },
  entityRepresentation: "Project",
  extra_buttons: [
    {
      label: "Create clip",
      clickHandler: (navigate, event, projectTitle) => {
        navigate("./edit", { state: { projectTitle } });
      },
    },
    {
      label: "View clips",
      clickHandler: (navigate, event, projectTitle) => {
        navigate("./clips", { state: { projectTitle } });
      },
    },
  ],
};

export const ProjectDetails: FC = detailsPageFactory(config);
