import { IconComponentProps } from "./types";

export default function AddVideoIcon({
  color = "#000000",
  size = 30,
}: IconComponentProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="SVGRepo_iconCarrier">
        <path
          d="M27.4,5.5H18.2L16.1,9.7H4.3V26.5H29.5V5.5Zm0,18.7H6.6V11.8H27.4Zm0-14.5H19.2l1-2.1h7.1V9.7Z"
          fill="#db847b"
        />

        <polygon
          points="25.7 13.7 0.5 13.7 4.3 26.5 29.5 26.5 25.7 13.7"
          fill="#db847b"
        />

        <path
          d="M12.879,10.281A1.921,1.921,0,0,1,13.9,10H29.172a1.908,1.908,0,0,1,1.817,1.463,2.527,2.527,0,0,1,.054.646V20.49h-1.18a1.36,1.36,0,0,0-.165.016,3.926,3.926,0,0,0-.436-.008c-.225.005-.457-.01-.685.01a1.706,1.706,0,0,0-.3-.015H24.724c-.15,0-.294.006-.44-.007l.075-.049-4.764-2.859v2.916c-1.66,0-3.32,0-4.979,0a1.705,1.705,0,0,0-.188.016,3.219,3.219,0,0,0-.481-.012c-.213,0-.427-.011-.64.011a1.539,1.539,0,0,0-.19-.016H11.96q.007-4.089,0-8.174a3.589,3.589,0,0,1,.053-.848A1.905,1.905,0,0,1,12.879,10.281Zm.4,1.641a.479.479,0,0,0-.367.454v.987a.479.479,0,0,0,.47.45h.982a.478.478,0,0,0,.45-.456c0-.319,0-.64,0-.959a.483.483,0,0,0-.247-.431.531.531,0,0,0-.268-.058h-.852A.811.811,0,0,0,13.284,11.922Zm15.254,0a.479.479,0,0,0-.355.455v.979a.478.478,0,0,0,.45.456c.327,0,.654,0,.981,0a.479.479,0,0,0,.47-.454c0-.328,0-.657,0-.985a.478.478,0,0,0-.445-.466c-.261-.007-.525,0-.784,0a1.485,1.485,0,0,0-.316.018ZM13.285,14.784a.478.478,0,0,0-.339.3.808.808,0,0,0-.031.289v.851a.478.478,0,0,0,.45.45c.3.006.6,0,.894,0a.619.619,0,0,0,.3-.049.481.481,0,0,0,.27-.43v-.959a.478.478,0,0,0-.45-.468c-.253-.006-.508,0-.762,0A1.788,1.788,0,0,0,13.285,14.784Zm15.251,0a.478.478,0,0,0-.325.3.8.8,0,0,0-.031.29v.848a.478.478,0,0,0,.456.455c.3,0,.609,0,.913,0a.554.554,0,0,0,.285-.058.48.48,0,0,0,.251-.418v-.963a.478.478,0,0,0-.445-.466c-.262-.007-.525,0-.785,0a1.446,1.446,0,0,0-.321.016ZM13.285,17.649a.479.479,0,0,0-.368.456c0,.328,0,.658,0,.986a.478.478,0,0,0,.47.45h.975a.478.478,0,0,0,.456-.455v-.98a.478.478,0,0,0-.454-.469c-.261-.005-.525,0-.784,0A1.59,1.59,0,0,0,13.285,17.649Zm15.249,0a.476.476,0,0,0-.322.3.8.8,0,0,0-.031.289v.848a.478.478,0,0,0,.455.456c.326,0,.652,0,.979,0a.479.479,0,0,0,.471-.454c0-.328,0-.657,0-.985a.478.478,0,0,0-.444-.466c-.262-.007-.525,0-.784,0a1.431,1.431,0,0,0-.323.011Z"
          fill="#e75749"
        />

        <path
          d="M19.6,17.574l4.764,2.859-.075.049q-2.345,1.4-4.688,2.811,0-1.4,0-2.8C19.6,19.52,19.6,18.549,19.6,17.574Z"
          fill="#ffffff"
        />

        <path
          d="M11.96,20.49h1.156a1.539,1.539,0,0,1,.19.016.484.484,0,0,0-.307.2.662.662,0,0,0-.083.388v.784a.514.514,0,0,0,.512.517h.9a.51.51,0,0,0,.493-.427c.006-.335,0-.675,0-1.007a.474.474,0,0,0-.391-.46,1.705,1.705,0,0,1,.188-.016h4.979v2.8q2.345-1.4,4.688-2.811c.15.013.293.005.44.007h3.555a1.706,1.706,0,0,1,.3.015.478.478,0,0,0-.356.289.737.737,0,0,0-.034.305v.76a.508.508,0,0,0,.475.538h.913a.513.513,0,0,0,.514-.493v-.738a.805.805,0,0,0-.075-.442.481.481,0,0,0-.315-.219,1.36,1.36,0,0,1,.165-.016h1.18c.008,1.291,0,2.582,0,3.873q0,2.36,0,4.719a1.925,1.925,0,0,1-.459,1.238,1.9,1.9,0,0,1-1.172.651,5.65,5.65,0,0,1-.736.025H14.547a6.951,6.951,0,0,1-1.026-.036,1.917,1.917,0,0,1-1.5-1.384,2.118,2.118,0,0,1-.067-.559V21.167C11.959,20.942,11.952,20.715,11.96,20.49Zm1.346,2.882a.46.46,0,0,0-.289.175.571.571,0,0,0-.1.348v.871a.514.514,0,0,0,.514.493h.962a.515.515,0,0,0,.426-.537V23.83a.463.463,0,0,0-.15-.343.534.534,0,0,0-.365-.127h-.852A.779.779,0,0,0,13.306,23.371Zm15.272,0a.465.465,0,0,0-.391.435v.916a.507.507,0,0,0,.474.538H28.7c.321,0,.643,0,.964,0a.525.525,0,0,0,.421-.513q0-.38,0-.76A.675.675,0,0,0,30,23.563a.5.5,0,0,0-.4-.2h-.715a1.684,1.684,0,0,0-.3.008ZM13.307,26.234a.457.457,0,0,0-.3.187.574.574,0,0,0-.093.336v.849a.525.525,0,0,0,.45.516H14.4a.515.515,0,0,0,.425-.538v-.892a.463.463,0,0,0-.15-.344.532.532,0,0,0-.363-.127h-.856A.771.771,0,0,0,13.307,26.234Zm15.269,0a.462.462,0,0,0-.369.32.742.742,0,0,0-.022.247v.9a.511.511,0,0,0,.492.425h.895a.517.517,0,0,0,.515-.518v-.894a.493.493,0,0,0-.214-.409,1.1,1.1,0,0,0-.59-.082c-.234.007-.472-.015-.7.013Z"
          fill="#c0392b"
        />
      </g>
    </svg>
  );
}
