import React from "react";
import VFIconComponent from "./icon/vf-icon";
import { formatFileSize } from "../utils";

interface UploadedFileViewProps {
  fileName: string;
  fileSize: number;
  onDelete: () => void;
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "center",
    minHeight: "288px",
  },
  wrapper: {
    width: "100%",
    maxWidth: "500px",
    height: "288px",
    backgroundColor: "#f8f9fa",
    borderRadius: "8px",
    padding: "24px",
    border: "2px dashed #e9ecef",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginBottom: "20px",
  },
  successIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "40px",
  },
  fileInfoBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "white",
    borderRadius: "8px",
    marginBottom: "16px",
    boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
    padding: "25px 20px",
  },
  fileInfoLeft: {
    display: "flex",
    alignItems: "center",
    flex: 1,
    minWidth: 0,
  },
  fileDetails: {
    flex: 1,
    minWidth: 0,
  },
  fileName: {
    fontSize: "14px",
    fontWeight: 500,
    color: "#1a1a1a",
    margin: 0,

    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap" as const,
  },
  fileSize: {
    margin: 0,
    fontSize: "14px",
    color: "#666",
  },

  statusMessage: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "14px",
    color: "#666",
    backgroundColor: "#f8f9fa",
    borderRadius: "6px",
    padding: "12px",
  },
  statusIcon: {
    marginRight: "8px",
  },
};

const UploadedFileView: React.FC<UploadedFileViewProps> = ({
  fileName,
  fileSize,
  onDelete,
}) => {
  const formattedSize = formatFileSize(fileSize);

  return (
    <div style={styles.container}>
      <p
        style={{
          textAlign: "left",
          color: "#212529",
          marginBottom: "25px",
        }}
      >
        Your file is successfully attached
      </p>

      <div style={styles.wrapper}>
        <div style={styles.successIcon}>
          <VFIconComponent type="success" size={48} color="#2ecc71" />
        </div>

        <div style={styles.fileInfoBox}>
          <div style={styles.fileInfoLeft}>
            <VFIconComponent type="video-file" size={50} />
            <div style={styles.fileDetails}>
              <p style={styles.fileName}>{fileName}</p>
              {formattedSize && <p style={styles.fileSize}>{formattedSize}</p>}
            </div>
          </div>
          <VFIconComponent
            style={{
              cursor: "pointer",
            }}
            onClick={onDelete}
            type="delete-filled"
            size={20}
          />
        </div>
      </div>
    </div>
  );
};

export default UploadedFileView;
