import { Modal, Button, createStyles, LoadingOverlay } from "@mantine/core";
import { FC, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import NewVideoCard from "../../components/NewVideoCard";
import SourceVideoCard from "../../components/SourceVideoCard";
import { useDeleteClipMutation } from "../../features/api/apiSlice";
import { showNotification } from "@mantine/notifications";
import "./Clips.scss";
import { useSelector } from "react-redux";
import { selectAuthToken } from "../../features/user/userSlice";
import { EDIT_ACTIONS_MAP } from "../../constants";
import { useMediaQuery } from "@mantine/hooks";
import { formatEntityTitle, getPreviousPath } from "../../utils";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  fetchVideoClips,
  selectVideoClips,
} from "../../features/videos/videosSlice";
import { useDispatch } from "react-redux";
import { IVideoClip } from "../../types";
import { RootState } from "../../store";
import VFIconComponent from "../../components/icon/vf-icon";
import DeleteEntityModal from "../../components/DeleteEntityModal";

export const Clips: FC = () => {
  const navigate = useNavigate(),
    location = useLocation(),
    { projectId: videoId } = useParams(),
    [deleteModalOpen, setDeleteModalOpen] = useState(false),
    [cutToDelete, setCutToDelete] = useState<string | null>(null),
    [deleteCut, { isLoading: isDeleting }] = useDeleteClipMutation();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [isDeletionInProgress, setDeletionInProgress] = useState(false);

  const clips: IVideoClip[] = useSelector((state: RootState) => {
    return selectVideoClips(state, videoId as string);
  });

  const dispatch = useDispatch();

  const initialFetchRef = useRef(false);

  const handleDeleteClick = (editId: string) => {
    setCutToDelete(editId);
    setDeleteModalOpen(true);
  };
  const handleConfirmDelete = async () => {
    if (cutToDelete) {
      setDeletionInProgress(true);
      try {
        await deleteCut(cutToDelete).unwrap();

        showNotification({
          radius: "md",
          title: "Success",
          message: "Clip deleted successfully.",
          icon: <VFIconComponent type="success" backgroundColor="#FFFFFF" />,
        });
      } catch (error) {
        showNotification({
          radius: "md",
          title: "Error",
          message: "Failed to delete the clip.",
          icon: <VFIconComponent type="error" backgroundColor="#FFFFFF" />,
        });
      }
    }

    setDeletionInProgress(false);

    setDeleteModalOpen(false);
  };

  const largeScreen = useMediaQuery("(min-width: 992px)");

  let content;

  const authToken = useSelector(selectAuthToken);

  useEffect(() => {
    const fetchData = async () => {
      const resultAction: PayloadAction<any> = await dispatch(
        fetchVideoClips(videoId)
      );

      if (fetchVideoClips.fulfilled.match(resultAction)) {
        setIsLoading(false);
      } else {
        showNotification({
          title: "Error",
          radius: "md",
          icon: <VFIconComponent type="error" backgroundColor="#FFFFFF" />,
          message: resultAction.payload.detail,
        });
      }
    };

    if (!initialFetchRef.current) {
      fetchData();
      initialFetchRef.current = true;
    }
  }, []);

  useEffect(() => {
    // Update the document title using the browser API
    if (!authToken) navigate("/");
  }, [authToken]);

  const handleEditAction = (actionType: string, editId: string) => {
    if (!actionType) return;
    switch (actionType) {
      case EDIT_ACTIONS_MAP.EDIT_DETAILS:
        navigate(`./${editId}`);
        break;
      case EDIT_ACTIONS_MAP.EDIT:
        navigate(`./${editId}/edit`);
        break;
      case EDIT_ACTIONS_MAP.DELETE_EDIT:
        handleDeleteClick(editId);
        break;
      default:
    }
  };

  const buttons = [
    {
      action: EDIT_ACTIONS_MAP.EDIT_DETAILS,
      title: "View Clip",
    },
    {
      action: EDIT_ACTIONS_MAP.DELETE_EDIT,
      title: "Delete Clip",
    },
  ];

  if (isLoading) {
    content = (
      <LoadingOverlay
        visible={true}
        loaderProps={{ size: "lg", variant: "dots" }}
      />
    );
  } else {
    content = clips?.map((clip: IVideoClip) => {
      let editTitle = formatEntityTitle(clip.title);

      return (
        <SourceVideoCard
          entityType="clip"
          key={clip.id}
          thumbnailUrl={clip.thumbnail_path}
          title={editTitle}
          id={clip.id}
          handleAction={handleEditAction}
          buttons={buttons}
          renderingStatus={clip.rendering_status}
        />
      );
    });

    content.unshift(
      <NewVideoCard
        entityType="clip"
        handleAddNewVideo={() => navigate(`./../edit`)}
      />
    );
  }

  return (
    <div className="projects-page-holder">
      <div className="back-btn-holder">
        <Button
          className="back-btn vf-button-filled"
          onClick={() => {
            navigate(getPreviousPath(location.pathname));
          }}
        >
          Back
        </Button>
      </div>
      <div className="video-cards-holder">{content}</div>
      <DeleteEntityModal
        isLoading={isDeletionInProgress}
        isOpen={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        entityRepresentation="Clip"
        handleConfirmDelete={handleConfirmDelete}
      />
    </div>
  );
};
