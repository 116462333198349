import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { RootState, store } from "./store";
import envConfig from "./envConfig";
import ReactGA from "react-ga4";
import { Provider } from "react-redux";
import { MantineProvider } from "@mantine/core";
import { NotificationsProvider } from "@mantine/notifications";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { AuthenticationPage } from "./pages/AuthenticationPage";
import { Projects } from "./pages/Projects/Projects";
import { Clips } from "./pages/Clips/Clips";
import { ProjectDetails } from "./pages/ProjectDetails/ProjectDetails";
import { EditDetails } from "./pages/EditDetails";
import ConfirmEmailModal from "./components/ConfirmEmailModal";
import Header from "./components/Header";
import { AuthProvider } from "./components/AuthContext";
import Editor from "./pages/Editor/Editor";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import ProtectedRoute from "./components/protected-route/ProtectedRoute";
import Breadcrumbs from "./components/Breadcrumbs";
import { hotjar } from "react-hotjar";
import { clarity } from "react-microsoft-clarity";
import { ModalProvider } from "./features/modal/ModalProvider";
import TagManager from "react-gtm-module";

function App() {
  const [showConfirmEmailModal, setShowConfirmEmailModal] = useState(false);
  const { isModalOpen, modalType } = useSelector((state: any) => state.modal);
  const { userData, emailConfirmed, subscriptionLevel, isAuthenticated } =
    useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    //! TODO: Add production flag in envConfig
    if (envConfig.hotjarId && !hotjar.initialized()) {
      hotjar.initialize({
        id: envConfig.hotjarId,
        sv: 6,
      });
    }

    if (envConfig.clarityId && !clarity.hasStarted()) {
      clarity.init(envConfig.clarityId);
    }

    if (envConfig.googleAnalyticsId && !ReactGA.isInitialized) {
      ReactGA.initialize(envConfig.googleAnalyticsId);
    }

    if (envConfig.googleTagManagerId) {
      TagManager.initialize({
        gtmId: envConfig.googleTagManagerId,
        dataLayer: { platform: "web" },
      });
    }

    return () => {
      dispatch({ type: "DISCONNECT_SSE" });
    };
  }, []);

  useEffect(() => {
    setShowConfirmEmailModal(userData && !emailConfirmed);
  }, [subscriptionLevel, emailConfirmed, userData]);

  if (showConfirmEmailModal)
    return (
      <ConfirmEmailModal
        userEmail={userData?.email}
        setShowModal={setShowConfirmEmailModal}
      />
    );

  return (
    <BrowserRouter>
      {isAuthenticated && <Header />}
      {/* <Content> */}
      {isAuthenticated && <Breadcrumbs />}
      <Routes>
        <Route path="/login" element={<AuthenticationPage />} />
        <Route path="/register" element={<AuthenticationPage />} />

        <Route
          path="/projects"
          element={<ProtectedRoute element={<Projects />} />}
        />

        <Route
          path="/projects/:projectId"
          element={<ProtectedRoute element={<ProjectDetails />} />}
        />

        <Route
          path="/projects/:projectId/edit"
          element={<ProtectedRoute element={<Editor />} />}
        />

        <Route
          path="/projects/:projectId/clips"
          element={<ProtectedRoute element={<Clips />} />}
        />

        <Route
          path="/projects/:projectId"
          element={<ProtectedRoute element={<ProjectDetails />} />}
        />

        <Route
          path="/projects/:projectId/clips/:editId"
          element={<ProtectedRoute element={<EditDetails />} />}
        />

        <Route
          path="/projects/:projectId/clips/:editId/edit"
          element={<ProtectedRoute element={<Editor />} />}
        />

        <Route path="*" element={<Navigate to="/projects" replace />} />
      </Routes>
    </BrowserRouter>
  );
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <MantineProvider
      withNormalizeCSS
      withGlobalStyles
      theme={{
        globalStyles: (theme) => ({
          fontFamily: "Poppins-Regular, Poppins-Bold, sans-serif",
          headings: { fontFamily: "Poppins-Bold, sans-serif" },
          button: {
            fontFamily: "Poppins-Regular, Poppins-Bold, sans-serif",
          },
          "#root": {
            display: "block",
            fontFamily: "Poppins-Regular, Poppins-Bold, sans-serif",
            width: "100%",
            height: "100%",
          },
          body: {
            display: "block",
            width: "100%",
            height: "100%",
            color: "#1C1C1C",
            fontFamily: "Poppins-Regular, Poppins-Bold, sans-serif",
            scrollbarColor: "#888 #f1f1f1",
          },
          ".vf-button-outlined": {
            color: "#29B1A1",
            borderColor: "#29B1A1",
            "&:hover": {
              backgroundColor: "#29B1A1",
              color: "#FFFFFF",
              borderColor: "#29B1A1",
            },
          },
          ".vf-button-filled": {
            color: "#FFFFFF",
            backgroundColor: "#29B1A1",
            borderColor: "#29B1A1",
            "&:hover": {
              color: "#29B1A1",
              backgroundColor: "#FFFFFF",
              borderColor: "#29B1A1",
            },
          },
        }),
        fontFamily: "Poppins-Regular, Poppins-Bold, sans-serif",
      }}
    >
      <NotificationsProvider>
        <Provider store={store}>
          <ModalProvider>
            <AuthProvider>
              <App />
            </AuthProvider>
          </ModalProvider>
        </Provider>
      </NotificationsProvider>
    </MantineProvider>
  </React.StrictMode>
);
