import {
  Alert,
  Button,
  Loader,
  Select,
  Switch,
  createStyles,
} from "@mantine/core";
import { IClipSizeOption } from "../../types";
import "./EditorOptions.scss";
import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import frameIcon from "./../../assets/icons/frame.svg";
import youtubeIcon from "./../../assets/icons/youtube.svg";
import tiktokIcon from "./../../assets/icons/tiktok.svg";
import instagramIcon from "./../../assets/icons/instagram.svg";
import vidfastLogo from "./../../assets/images/vidfast-logo.svg";
import captionsIcon from "./../../assets/icons/captions.svg";
import React from "react";
import { ISubtitles } from "../../remotion/types";
import { fetchSubtitles } from "../../features/videos/videosSlice";
import { useDispatch } from "react-redux";
import { setSubtitles } from "../../features/clipConfig/clipConfigSlice";
import { DEFAULT_SUBTITLES_WORDS_PER_LINE } from "../../constants";
import VFIconComponent from "../icon/vf-icon";

const clipSizeOptions: IClipSizeOption[] = [
  {
    id: 1,
    label: "Original size",
    description: "",
    value: "original",
    size: "original",
    icon: frameIcon,
  },
  // {
  //   id: 2,
  //   label: "YouTube",
  //   description: "16:9",
  //   value: "youtube",
  //   size: "16:9",
  //   icon: youtubeIcon,
  // },
  {
    id: 3,
    label: "YouTube Shorts",
    description: "9:16",
    value: "youtube-shorts",
    size: "9:16",
    icon: youtubeIcon,
  },
  {
    id: 4,
    label: "TikTok",
    description: "9:16",
    value: "tiktok",
    size: "9:16",
    icon: tiktokIcon,
  },
  {
    id: 5,
    label: "Instagram post",
    description: "1:1",
    value: "instagram-post",
    size: "1:1",
    icon: instagramIcon,
  },
  {
    id: 6,
    label: "Instagram reel",
    description: "9:16",
    value: "instagram-reel",
    size: "9:16",
    icon: instagramIcon,
  },
];

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  icon: string;
  label: string;
  description: string;
  value: string;
}

export interface EditorOptionSelectedEvent {
  type: "clip-size" | "captions" | "back";
  value?: any;
}

const SelectItem = memo(
  forwardRef<HTMLDivElement, ItemProps>(
    ({ icon, label, description, ...others }: ItemProps, ref) => (
      <div ref={ref} {...others}>
        <div className="clip-size-option-element">
          <img src={icon} alt="Social Media Logo" />
          <span className="size-option-label">
            {label}
            {description && (
              <span className="size-option-description">{`(${description})`}</span>
            )}
          </span>
        </div>
      </div>
    )
  )
);

SelectItem.displayName = "SelectItem";

const useStyles = createStyles((theme) => ({
  selectItem: {
    borderRadius: "10px",
    "&[data-selected]": {
      background: "#29b1a1",
      "&:hover": {
        background: "#29b1a1",
      },
    },
  },
  selectDropdown: {
    borderRadius: "16px",
  },
  selectRoot: {
    boxShadow: "0px 4px 6px 0px #C8C8C840",
    borderRadius: "16px",
  },
  selectIcon: {
    width: "auto",
    marginLeft: 17,
    img: {
      display: "block",
      width: 22,
    },
  },
  captionsButtonRoot: {
    borderRadius: "16px",
    background: "#FFF",
    color: "#000",
    fontWeight: "normal",
    boxShadow: "0px 4px 6px 0px #C8C8C840",
    border: "1px solid #ced4da",
    "&:hover": {
      background: "#FFF",
      borderColor: "#29b1a1",
    },
    "&:disabled": {
      cursor: "not-allowed",
    },
  },
  captionsButtonIcon: {
    img: {
      display: "block",
      width: 22,
    },
  },
  backButtonRoot: {
    borderRadius: "16px",
    background: "#29b1a1",
    border: "2px solid #29B1A1",
    color: "#FFF",
    boxShadow: "0px 4px 6px 0px #C8C8C840",
    width: "100%",
    fontSize: "18px",
    lineHeight: "22px",

    "&:hover": {
      background: "#FFF",
      color: "#29b1a1",
      borderColor: "#29b1a1",
    },
  },
  switchTrack: {
    "&input:checked": {
      backgroundColor: "#29B1A1",
      borderColor: "#29B1A1",
    },
  },
  switchInput: {
    cursor: "pointer",
    "&:checked+.mantine-Switch-track": {
      backgroundColor: "#29B1A1",
      borderColor: "#29B1A1",
    },
  },
}));

interface EditorOptionsProps {
  handleOptionChange: (optionSelectedEvent: EditorOptionSelectedEvent) => void;
  isVideoPortrait: boolean;
  video: Video;
  subtitles: ISubtitles | null;
  activeState: {
    subtitles: boolean;
    clipSize: string;
  };
}

const EditorOptions = React.memo(
  ({
    handleOptionChange,
    isVideoPortrait,
    video,
    subtitles,
    activeState,
  }: EditorOptionsProps) => {
    const { classes } = useStyles();
    const [selectedSizeOption, setSelectedSizeOption] = useState<any>(
      activeState.clipSize
    );
    const [subtitlesEnabled, setSubtitlesEnabled] = useState<boolean>(
      subtitles && subtitles.items && subtitles.items.length ? true : false
    );

    const [subtitlesLoading, setSubtitlesLoading] = useState<boolean>(false);

    const prepareOptionChangeArgs = (
      type: "captions" | "clip-size" | "back",
      value?: any
    ): EditorOptionSelectedEvent => {
      return {
        type,
        ...(value && {
          value,
        }),
      };
    };

    const getOptionProperty = useCallback(
      (optionValue: string, propertyName?: string) => {
        if (!optionValue) return "";

        const option = (clipSizeOptions as { [key: string]: any }).find(
          (item: any) => item.value === optionValue
        );

        return propertyName ? option[propertyName] : option;
      },
      [activeState.clipSize, selectedSizeOption]
    );

    const frameSizes = useMemo(() => {
      return clipSizeOptions.filter(
        (option) => (isVideoPortrait ? option.size !== "9:16" : true) // remove 9:16 options if video is portrait
      );
    }, [isVideoPortrait]);

    const frameSizeChange = (optionValue: string) => {
      if (optionValue) {
        const option = getOptionProperty(optionValue);

        const simplifiedOption = {
          value: option.value,
          size: option.size,
        };

        setSelectedSizeOption(simplifiedOption);

        handleOptionChange(
          prepareOptionChangeArgs("clip-size", simplifiedOption)
        );
      }
    };

    const captionsButtonClick = () =>
      handleOptionChange(prepareOptionChangeArgs("captions"));

    const backButtonClick = () => {
      dispatch(
        setSubtitles({
          items: null,
        })
      );

      handleOptionChange(prepareOptionChangeArgs("back"));
    };

    const dispatch = useDispatch();

    const subtitlesReady = video.processing_status === "SUCCESS";

    useEffect(() => {
      setSelectedSizeOption(activeState.clipSize);
    }, [activeState.clipSize]);

    useEffect(() => {
      setSubtitlesEnabled(
        subtitles && subtitles.items && subtitles.items.length ? true : false
      );
    }, [subtitles]);

    return (
      <div className="editor-options-holder">
        <div className="clip-size-option">
          <Select
            icon={
              <img
                src={getOptionProperty(selectedSizeOption.value, "icon")}
              ></img>
            }
            transition="pop-top-left"
            transitionDuration={80}
            transitionTimingFunction="ease"
            classNames={{
              item: classes.selectItem,
              dropdown: classes.selectDropdown,
              root: classes.selectRoot,
              icon: classes.selectIcon,
            }}
            value={selectedSizeOption.value}
            placeholder={"Search"}
            data={frameSizes}
            radius="lg"
            searchable
            maxDropdownHeight={400}
            defaultValue={selectedSizeOption.value}
            nothingFound="Nobody here"
            size="lg"
            itemComponent={SelectItem}
            rightSection={<VFIconComponent type="arrow-down" size={18} />}
            onChange={frameSizeChange}
          />
        </div>
        <div className="open-captions-settings-option">
          <div className="actions-holder">
            <Button
              size="lg"
              classNames={{
                leftIcon: classes.captionsButtonIcon,
                root: classes.captionsButtonRoot,
              }}
              leftIcon={<img src={captionsIcon}></img>}
              onClick={captionsButtonClick}
              disabled={!subtitlesEnabled}
            >
              Subtitles
            </Button>
            <div className="switch-holder" style={{ position: "relative" }}>
              <Switch
                disabled={!subtitlesReady}
                size={"xl"}
                onLabel="ON"
                offLabel="OFF"
                checked={subtitlesEnabled}
                onChange={async (e) => {
                  const isChecked = e.target.checked;

                  if (isChecked) {
                    setSubtitlesLoading(true);

                    if (
                      subtitles &&
                      subtitles.items &&
                      subtitles.items.length
                    ) {
                      dispatch(
                        setSubtitles({
                          items: video.subtitles,
                        })
                      );
                    } else {
                      const fetchSubtitlesAction = await dispatch(
                        fetchSubtitles({
                          video_id: video.id,
                          words_per_line: DEFAULT_SUBTITLES_WORDS_PER_LINE,
                        })
                      );

                      await dispatch(
                        setSubtitles({
                          items: fetchSubtitlesAction.payload.subtitles,
                        })
                      );
                    }

                    setSubtitlesLoading(false);
                  } else {
                    dispatch(
                      setSubtitles({
                        items: null,
                      })
                    );
                  }

                  setSubtitlesEnabled(isChecked);
                }}
                classNames={{
                  input: classes.switchInput,
                  track: classes.switchTrack,
                }}
              />
              {subtitlesLoading && (
                <Loader
                  style={{
                    position: "absolute",
                    left: "7px",
                    top: "50%",
                    transform: "translateY(-50%)",
                  }}
                  color="blue"
                  size={20}
                />
              )}
            </div>
          </div>
          {!subtitlesReady && (
            <div className="info-holder">
              <Alert
                icon={
                  <VFIconComponent
                    className="label-icon"
                    type="info"
                    size={16}
                  />
                }
                variant="light"
                color="blue"
              >
                Your AI Subtitles are generating..
                <Loader
                  style={{
                    position: "absolute",
                    right: "16px",
                    top: "16px",
                  }}
                  color="blue"
                  size={14}
                />
              </Alert>
            </div>
          )}
        </div>
        <div className="back-button-holder">
          <Button
            size="lg"
            classNames={{
              root: classes.backButtonRoot,
            }}
            leftIcon={<img src={vidfastLogo}></img>}
            onClick={backButtonClick}
          >
            Back to Projects
          </Button>
        </div>
      </div>
    );
  }
);

EditorOptions.displayName = "EditorOptions";

export default EditorOptions;
