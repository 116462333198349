import { AbsoluteFill, useCurrentFrame } from "remotion";
import { Subtitles } from "./components/Subtitles";
import { IClipConfig, IVideoSegment } from "./types";
import VideoCropOverlay from "./components/VideoCropOverlay";
import { RemotionContextProvider } from "./RemotionContext";
import { getCurrentSegment } from "./helpers";
import "./index.css";

export const MyComposition: React.FC<{ props: IClipConfig }> = ({ props }) => {
  const {
    sourceVideo,
    compositionDimensions,
    subtitles,
    renderMode,
    segments,
  } = props;

  // Video ratio for actual video dimensions / video player dimensions
  const videoRatio: number =
    compositionDimensions.height / sourceVideo.media_metadata.height;

  const frame = useCurrentFrame();
  const timeInSeconds = frame / props.sourceVideo.media_metadata.fps;
  const currentSegment: IVideoSegment | null = getCurrentSegment(
    timeInSeconds,
    segments
  );

  return (
    <RemotionContextProvider value={{ ...props, videoRatio }}>
      <AbsoluteFill
        style={{
          position: "relative",
        }}
      >
        {!renderMode && currentSegment && currentSegment.crop_box && (
          <VideoCropOverlay />
        )}

        {(!currentSegment || !currentSegment.crop_box) &&
          subtitles &&
          subtitles.items && <Subtitles />}

        {renderMode && subtitles && subtitles.items && <Subtitles />}
      </AbsoluteFill>
    </RemotionContextProvider>
  );
};
