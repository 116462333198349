import {
  createContext,
  ReactNode,
  FC,
  useEffect,
  useState,
  useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserData } from "../features/auth/authActions";
import { IUserData } from "../types";
import { AppDispatch, RootState } from "../store";
import { fetchVideos } from "../features/videos/videosSlice";

interface AuthContextProps {
  isAuthenticated: boolean;
  loading: boolean;
  userData: IUserData | null;
}

const AuthContext = createContext<AuthContextProps>({
  isAuthenticated: false,
  loading: true,
  userData: null,
});

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { isAuthenticated, userData, emailConfirmed, subscriptionLevel } =
    useSelector((state: RootState) => state.auth);

  const [loading, setLoading] = useState(true);

  const initialFetchRef = useRef(false);

  useEffect(() => {
    if (isAuthenticated && !userData) {
      dispatch(getUserData());
    }
  }, [isAuthenticated, userData]);

  useEffect(() => {
    const fetchData = async () => {
      if (!initialFetchRef.current) {
        initialFetchRef.current = true;

        const fetchData = async () => {
          try {
            const resultAction = await dispatch(getUserData());

            if (getUserData.fulfilled.match(resultAction)) {
              await dispatch(fetchVideos());

              setLoading(false);
            }

            setLoading(false);
          } catch (error) {
            console.warn("error", error);
          }
        };
        fetchData();
      }
    };

    fetchData();
  }, [dispatch]);

  return (
    <AuthContext.Provider value={{ isAuthenticated, loading, userData }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
