import { createAsyncThunk } from "@reduxjs/toolkit";
import envConfig from "../../envConfig";
import { ILoginPayload, IRegisterPayload } from "../../types";
import { Cookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

export const registerUser = createAsyncThunk(
  "auth/register",
  async (payload: IRegisterPayload, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const response = await fetch(`${envConfig.baseApi}/register`, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: config.headers,
      });

      const data = await response.json();

      if (!response.ok) return rejectWithValue(data);

      return data;
    } catch (error: any) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const cookies = new Cookies();

export const userLogin = createAsyncThunk(
  "auth/login",
  async (payload: ILoginPayload, { rejectWithValue }) => {
    const config = {
      headers: {
        //   "Content-Type": "application/json",
      },
    };

    const response = await fetch(`${envConfig.baseApi}/login`, {
      method: "POST",
      body: new URLSearchParams({
        username: payload.username,
        password: payload.password,
      }),
      headers: config.headers,
    });

    const data = await response.json();

    if (!response.ok) return rejectWithValue(data);

    return data;
  }
);

export const getUserData = createAsyncThunk(
  "auth/getUserData",
  async (_, { rejectWithValue }) => {
    try {
      const userToken = cookies.get("user_auth");

      const response = await fetch(`${envConfig.baseApi}/me`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });

      const data = await response.json();

      if (!response.ok) return rejectWithValue(data);

      return data;
    } catch (error: any) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
