import { LoadingOverlay, Modal, Transition, createStyles } from "@mantine/core";
import { useResendConfirmationEmailMutation } from "../features/api/apiSlice";
import { showNotification } from "@mantine/notifications";
import { handleErrors } from "../utils";
import receivedEmailIcon from "./../assets/icons/received-email.svg";
import emailResentIcon from "./../assets/icons/email-resent.svg";
import "./ConfirmEmailModal.scss";
import { useState } from "react";
import VFIconComponent from "./icon/vf-icon";

interface ConfirmEmailModalProps {
  setShowModal: (show: boolean) => void;
  userEmail: string | undefined;
}

const useStyles = createStyles((theme) => ({
  title: {
    fontWeight: "bold",
    fontSize: "24px",
    textAlign: "center",
    margin: "0 auto",
  },
  body: {
    overflow: "hidden",
  },
  root: {
    textAlign: "center",
    marginTop: 35,
    minHeight: 100,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  inner: {
    ".mantine-Paper-root": {
      borderRadius: 10,
    },
  },
}));

const ConfirmEmailModal: React.FC<ConfirmEmailModalProps> = ({
  setShowModal,
  userEmail,
}) => {
  const [resendConfirmationEmail, { isLoading }] =
    useResendConfirmationEmailMutation();
  const { classes, theme } = useStyles();

  const [emailResent, setEmailResent] = useState(false);

  const handleSubmit = async () => {
    try {
      await resendConfirmationEmail().unwrap();

      showNotification({
        title: "Success",
        message: "Confirmation email sent successfully.",
        color: "teal",
        icon: <VFIconComponent type="success" backgroundColor="#FFFFFF" />,
      });

      setEmailResent(true);
    } catch (error) {
      handleErrors(error);
    }
  };

  return (
    <Modal
      classNames={{
        root: classes.root,
        title: classes.title,
        body: classes.body,
        inner: classes.inner,
      }}
      opened={true}
      onClose={() => setShowModal(false)}
      centered={true}
      overlayOpacity={0.55}
      overlayBlur={3}
      size={650}
      closeOnClickOutside={false}
      closeOnEscape={false}
      padding={0}
      withCloseButton={false}
    >
      <LoadingOverlay
        visible={isLoading}
        loaderProps={{ size: "lg", variant: "dots" }}
      />
      <div className="confirm-email-modal-header"></div>
      <div className="confirm-email-modal-body">
        {!emailResent && (
          <>
            <div className="icon-holder">
              <img
                src={receivedEmailIcon}
                className="received-email-icon"
                alt="email-icon"
              />
            </div>
            <div className="content-holder">
              <h1>Confirm your email</h1>
              <p className="email-confirmation-description">
                To fully access all the features, please confirm your email
                address by clicking the link in the email we sent to{" "}
                <span className="user-email-holder">{userEmail}</span>.
              </p>
            </div>
            <div className="confirm-email-modal-footer">
              <p className="email-confirmation-footnote">
                If you didn't receive it,{" "}
                <span className="resend-email-link" onClick={handleSubmit}>
                  click here to resend the email
                </span>
                .
              </p>
            </div>
          </>
        )}

        <Transition
          mounted={emailResent}
          transition="slide-left"
          duration={500}
          timingFunction="ease"
        >
          {(styles) => (
            <div style={{ ...styles }} className="">
              <div
                className="icon-holder"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <img
                  src={emailResentIcon}
                  className="received-email-icon"
                  alt="email-icon"
                />
              </div>
              <div className="content-holder">
                <h1>Email successfully sent!</h1>
              </div>
              <p>
                Don't see it? Check your spam folder or use the search function
                in your email client.
              </p>
            </div>
          )}
        </Transition>
      </div>
    </Modal>
  );
};

export default ConfirmEmailModal;
