import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Cookies } from "react-cookie";
import envConfig from "../../envConfig";
import { apiSlice } from "../api/apiSlice";

const initialState = {
  user: null,
  authToken: null,
  projects: null,
  cuts: null,
  currentProject: null,
  currentCut: null,
};

const cookies = new Cookies();

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setAuthToken(state, action) {
      const authToken: any = action.payload;

      //! TODO: encrypt token
      cookies.set("user_auth", authToken, { path: "/" });

      state.authToken = authToken;
    },
    setUser(state, action) {
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      apiSlice.endpoints.getFonts.matchFulfilled,
      (state, { payload }) => {
        if (payload && payload.length) {
          payload.forEach((element: any) => {
            const font = new FontFace(
              element.font_name,
              `url(${envConfig.baseApi}${element.font_path})`
            );
            // Add to the document.fonts (FontFaceSet)
            document.fonts.add(font);
          });
        }
      }
    ),
      builder.addMatcher(
        apiSlice.endpoints.getVideos.matchFulfilled,
        (state, { payload }) => {
          const cuts: any = [];

          if (payload && payload.length) {
            payload.forEach((video: any) => {
              if (video.cuts && video.cuts.length) {
                video.cuts.forEach((cut: any) => {
                  cuts.push(cut);
                });
              }
            });
          }

          state.projects = payload;
          state.cuts = cuts;
        }
      ),
      builder.addMatcher(
        apiSlice.endpoints.getVideo.matchFulfilled,
        (state, { payload }) => {
          state.currentProject = payload;
        }
      ),
      builder.addMatcher(
        apiSlice.endpoints.getClip.matchFulfilled,
        (state, { payload }) => {
          state.currentCut = payload;
        }
      );
  },
});

export const { setAuthToken, setUser } = userSlice.actions;

// export const selectAuthToken = (state: any) => state.user.authToken;

export const selectAuthToken = createSelector(
  (state: any) => state.user.authToken,
  (authTokenInState) => {
    if (authTokenInState) {
      return authTokenInState;
    }
    return cookies.get("user_auth");
  }
);
export const selectUser = (state: any) => state.user.user;

export const selectProjects = (state: any) => state.user.projects;

export const selectCurrentProject = (state: any) => state.user.currentProject;

export const selectCurrentCut = (state: any) => state.user.currentCut;

export const selectCuts = (state: any) => state.user.cuts;

export default userSlice.reducer;
