import React, { createContext, useContext, ReactNode } from "react";
import { IClipConfig, IVideoSegment } from "./types";
import { useCurrentFrame } from "remotion";
import { getCurrentSegment } from "./helpers";

const RemotionContext = createContext<
  | (IClipConfig & {
      videoRatio: number;
      currentSegment?: IVideoSegment | null;
    })
  | null
>(null);

// Create a provider component
interface RemotionContextProviderProps {
  value: IClipConfig & {
    videoRatio: number;
    currentSegment?: IVideoSegment | null;
  };
  children: ReactNode;
}

export const RemotionContextProvider: React.FC<
  RemotionContextProviderProps
> = ({ value, children }) => {
  const frame = useCurrentFrame();
  const timeInSeconds = frame / value.sourceVideo.media_metadata.fps;
  const currentSegment: IVideoSegment | null = getCurrentSegment(
    timeInSeconds,
    value.segments
  );

  return (
    <RemotionContext.Provider value={{ ...value, currentSegment }}>
      {children}
    </RemotionContext.Provider>
  );
};

export const useRemotionContext = (): IClipConfig & {
  videoRatio: number;
  currentSegment?: IVideoSegment | null;
} => {
  const context = useContext(RemotionContext);

  if (context === null) {
    throw new Error(
      "useRemotionContext must be used within a RemotionContextProviderProps"
    );
  }
  return context;
};
