import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import "./SubtitleItem.scss";
import filledDeleteIcon from "../../assets/icons/delete-filled.svg";
import { formatTime } from "../../utils";
import { ISubtitle, ISubtitleWord } from "../../remotion/types";
import { Button } from "@mantine/core";

interface SubtitleEditorProps {
  subtitle: ISubtitle;
  onUpdate: (updatedSubtitle: ISubtitle, action: "delete" | "update") => void;
  isActive: boolean;
}

const SubtitleItem: React.FC<SubtitleEditorProps> = memo(
  ({ subtitle, onUpdate, isActive }) => {
    const [editableWordIndex, setEditableWordIndex] = useState<number | null>(
      null
    );
    const [editedWords, setEditedWords] = useState<string[]>(
      subtitle.words.map((word) => word.word)
    );

    const subtitleRef = useRef(null);

    const handleWordUpdate = (index: number, updatedWord: string) => {
      const newEditedWords = [...editedWords];
      newEditedWords[index] = updatedWord;
      setEditedWords(newEditedWords);
    };

    const handleBlur = (index: number) => {
      const originalWord = subtitle.words[index];
      const updatedText = editedWords[index];
      const updatedWordsArray = updatedText.split(" "); // Split the updated text into words

      // Calculate the total duration available for the original word
      const totalDuration = originalWord.end - originalWord.start;
      const durationPerWord = totalDuration / updatedWordsArray.length;

      const newWords = updatedWordsArray.map((word, i) => ({
        ...originalWord,
        start: originalWord.start + i * durationPerWord,
        end: originalWord.start + (i + 1) * durationPerWord,
        word: word,
      }));

      // Replace the original word in the array with the new words
      const updatedWords = [
        ...subtitle.words.slice(0, index),
        ...newWords,
        ...subtitle.words.slice(index + 1),
      ];

      onUpdate(
        {
          ...subtitle,
          text: concatWordsToText(updatedWords),
          words: updatedWords,
        },
        "update"
      );

      setEditableWordIndex(null); // Exit editable state
    };

    const concatWordsToText = useCallback((words: ISubtitleWord[]): string => {
      return words.reduce(
        (acc, word, i) =>
          acc + (i < words.length - 1 ? `${word.word} ` : word.word),
        ""
      );
    }, []);

    const handleWordDelete = (index: number) => {
      const updatedWords = subtitle.words.filter((_, i) => i !== index);
      setEditedWords((prev) => prev.filter((_, i) => i !== index));

      onUpdate(
        {
          ...subtitle,
          text: concatWordsToText(updatedWords),
          words: updatedWords,
        },
        "update"
      );
    };

    const handleNewWordAdd = (newWord: string) => {
      const newWordObj = { start: 0, end: 0, word: newWord }; // Placeholder times, will be recalculated
      const updatedWords = [...subtitle.words, newWordObj];
      recalculateTimes(updatedWords);
    };

    const handleKeyDown = (
      e: React.KeyboardEvent<HTMLInputElement>,
      index: number
    ) => {
      if (e.key === "Enter") {
        e.preventDefault(); // Prevents the default action of the enter key
        handleBlur(index); // Assuming handleInputBlur is the method you use to handle the onBlur event
      }
    };

    const recalculateTimes = (wordsArray: ISubtitleWord[]) => {
      const totalDuration = subtitle.end - subtitle.start;
      const totalCharacters = wordsArray.reduce(
        (acc, word) => acc + word.word.replace(/ /g, "").length,
        0
      );
      // Use the exact duration per character without rounding it here.
      const timePerCharacter = totalDuration / totalCharacters;

      let currentTime = subtitle.start;

      const updatedWords = wordsArray.map((word, index) => {
        const wordLength = word.word.replace(/ /g, "").length;
        // Calculate the exact duration for this word.
        const wordDuration = wordLength * timePerCharacter;

        const start = currentTime;
        let end = start + wordDuration;

        // Ensure precise calculation: round only when setting the final value.
        // For the last word, adjust end time to match the subtitle's end precisely.
        if (index === wordsArray.length - 1) {
          end = subtitle.end; // Ensure the last word ends exactly at the subtitle's end time.
        } else {
          // Update currentTime for the next word without rounding here.
          currentTime = end;
        }

        return {
          ...word,
          // Round start and end times here if needed, otherwise, keep as precise calculation.
          start: parseFloat(start.toFixed(2)),
          end: parseFloat(end.toFixed(2)),
        };
      });

      // Update the subtitle with recalculated times and inform the parent component.
      onUpdate(
        {
          ...subtitle,
          text: concatWordsToText(updatedWords),
          words: updatedWords,
        },
        "update"
      );
    };

    return (
      <div
        className={`subtitle-holder ${isActive ? "active" : ""}`}
        ref={subtitleRef}
      >
        <div className="editor-holder">
          {subtitle.words.map((word, index) => (
            <React.Fragment key={index}>
              {editableWordIndex === index ? (
                <input
                  type="text"
                  value={editedWords[index]}
                  autoFocus
                  onBlur={() => handleBlur(index)} // Pass the index to handleBlur
                  onChange={(e) => handleWordUpdate(index, e.target.value)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  style={{
                    width: `${Math.max(3, editedWords[index].length)}ch`,
                  }}
                  className="word-input"
                />
              ) : (
                <span
                  className="word-span"
                  onClick={() => setEditableWordIndex(index)}
                >
                  {word.word}
                  <span
                    className="delete-word-btn"
                    onClick={(event) => {
                      event.stopPropagation();
                      handleWordDelete(index);
                    }}
                  >
                    <img
                      className="delete-button"
                      src={filledDeleteIcon}
                      alt="Delete"
                    />
                  </span>
                </span>
              )}{" "}
            </React.Fragment>
          ))}
          <Button
            className="add-word-btn"
            onClick={() => handleNewWordAdd("new")}
          >
            +
          </Button>
        </div>
        <div className="times-holder">
          <div className="labels">
            <span className="time-label">In</span>
            <span className="time-label">Out</span>
          </div>
          <div className="times">
            <input
              disabled
              className="time-input"
              type="text"
              value={formatTime(subtitle.start)}
              // onChange={handleChange(setStart, setStartDisplay)}
            />
            <input
              disabled
              className="time-input"
              type="text"
              value={formatTime(subtitle.end)}
              // onChange={handleChange(setEnd, setEndDisplay)}
            />
          </div>
        </div>
        <div className="delete-subtitle-btn-holder">
          <img
            className="delete-subtitle-btn"
            src={filledDeleteIcon}
            alt="Delete"
            onClick={() => onUpdate({ ...subtitle }, "delete")}
          />
        </div>
      </div>
    );
  }
);

SubtitleItem.displayName = "SubtitleItem";

export default SubtitleItem;
