import changePasswordIcon from "./../../assets/icons/change-password.svg";
import logoutIcon from "./../../assets/icons/logout.svg";
import storageIcon from "./../../assets/icons/storage.svg";
import subscriptionIcon from "./../../assets/icons/subscription.svg";
import userAvatarIcon from "./../../assets/icons/user-avatar.svg";
import { useGetCustomerPortalUrlMutation } from "../../features/api/apiSlice";
import { handleErrors } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "./../../features/modal/modalSlice";
import "./UserDropdown.scss";
import { logout } from "../../features/auth/authSlice";
import { RootState } from "../../store";
import { ModalConfig, ModalType } from "../../features/modal/types";

// Static config for dropdown actions
const DROPDOWN_ACTIONS = [
  {
    title: "Manage subscription",
    name: "manage-subscription",
    icon: subscriptionIcon,
  },
  { title: "Usage quota", name: "usage-quota", icon: storageIcon },
  {
    title: "Change password",
    name: "change-password",
    icon: changePasswordIcon,
  },
  { title: "Logout", name: "logout", icon: logoutIcon },
];

export const UserDropdown = ({}) => {
  const { subscriptionLevel } = useSelector((state: RootState) => state.auth);

  const handleAction = (name: string) => {
    switch (name) {
      case "manage-subscription":
        // Free trial
        if (subscriptionLevel === 0) {
          const nextModalConfig: ModalConfig = {
            type: ModalType.SUBSCRIPTION,
          };

          dispatch(openModal(nextModalConfig));
        } else {
          getCustomerPortalUrl()
            .unwrap()
            .then((data) => {
              window.open(data.url, "_blank");
            })
            .catch((error) => {
              handleErrors(error);
            });
        }

        break;
      case "usage-quota":
        const modalConfig: ModalConfig = {
          type: ModalType.USAGE_QUOTA,
        };

        dispatch(openModal(modalConfig));

        break;
      case "change-password":
        const changePasswordModalConfig: ModalConfig = {
          type: ModalType.CHANGE_PASSWORD,
        };

        dispatch(openModal(changePasswordModalConfig));

        break;
      case "logout":
        dispatch(logout());

        break;
      default:
    }
  };

  const [getCustomerPortalUrl, { data: customerPortal }] =
    useGetCustomerPortalUrlMutation();
  const dispatch = useDispatch();

  return (
    <div className="user-dropdown">
      <img className="avatar" src={userAvatarIcon} />
      <div className="dropdown">
        {DROPDOWN_ACTIONS.map((action) => (
          <div
            key={action.name}
            className="dropdown-item"
            onClick={() => handleAction(action.name)}
          >
            <img src={action.icon} className="item-icon" />
            <span className="item-title">{action.title}</span>
          </div>
        ))}
      </div>
    </div>
  );
};
