import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../store"; // Adjust the import path as needed
import { IVideo, ProcessingStatus, IVideoClip } from "../../types";
import envConfig from "../../envConfig";
import { apiSlice } from "../api/apiSlice";
import { ISubtitle } from "../../remotion/types";
import {
  FetchSubtitlesPayload,
  FetchSubtitlesArgs,
  CreateClipArgs,
  Video,
} from "./types";

import.meta.env.DEV;

interface VideoState {
  videos: Video[];
  loadingStatus: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

const initialState: VideoState = {
  videos: [],
  loadingStatus: "idle",
  error: null,
};

// Async thunk for fetching subtitles

// Updated interface for the fetchSubtitles thunk payload

const authorizedFetch = async (
  url: string,
  state: RootState,
  options: RequestInit = {}
) => {
  const token = state.auth.userToken;
  const headers = new Headers(options.headers || {});

  if (token) {
    headers.set("Authorization", `Bearer ${token}`);
  }

  const response = await fetch(url, {
    ...options,
    headers,
  });

  return response;
};

// Updated fetchSubtitles thunk to use the new payload structure
export const fetchSubtitles = createAsyncThunk<
  FetchSubtitlesPayload,
  FetchSubtitlesArgs,
  { state: RootState }
>(
  "videos/fetchSubtitles",
  async ({ video_id, words_per_line }, { getState, rejectWithValue }) => {
    const token = getState().auth.userToken;
    const requestBody = JSON.stringify({
      video_id,
      words_per_line,
    });

    try {
      const response = await fetch(`${envConfig.baseApi}/generate-subtitles`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: requestBody,
      });

      const data: ISubtitle[] = await response.json();

      if (!response.ok) {
        return rejectWithValue({
          statusCode: response.status,
          error: data,
        });
      }

      return { video_id, subtitles: data, words_per_line };
    } catch (error) {
      return rejectWithValue({
        error: {
          detail: [
            {
              msg: "Something went wrong..",
            },
          ],
        },
        statusCode: 500,
      });
    }
  }
);

export const createClip = createAsyncThunk<
  string,
  CreateClipArgs,
  { state: RootState }
>(
  "videos/createClip",
  async ({ videoId, clipPayload }, { getState, rejectWithValue }) => {
    try {
      const response = await authorizedFetch(
        `${envConfig.baseApi}/videos/${videoId}/clips`,
        getState(),
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(clipPayload),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        return rejectWithValue({
          statusCode: response.status,
          error: data,
        });
      }

      return data;
    } catch (error) {
      return rejectWithValue({
        error: {
          detail: [
            {
              msg: "Something went wrong..",
            },
          ],
        },
        statusCode: 500,
      });
    }
  }
);

export const addNewVideo = createAsyncThunk<any, IVideo, { state: RootState }>(
  "videos/addNewVideo",
  async (video, { getState, rejectWithValue }) => {
    try {
      const response = await authorizedFetch(
        `${envConfig.baseApi}/videos`,
        getState(),
        {
          method: "POST",
          body: video,
        }
      );

      const data: IVideo = await response.json();

      if (!response.ok) {
        return rejectWithValue({
          statusCode: response.status,
          error: data,
        });
      }

      return data;
    } catch (error) {
      return rejectWithValue({
        error: {
          detail: [
            {
              msg: "Something went wrong..",
            },
          ],
        },
        statusCode: 500,
      });
    }
  }
);

export const deleteVideo = createAsyncThunk(
  "videos/deleteVideo",
  async (videoId: string, { getState, rejectWithValue }) => {
    try {
      const response = await authorizedFetch(
        `${envConfig.baseApi}/videos/${videoId}`,
        getState(),
        {
          method: "DELETE",
        }
      );

      if (!response.ok) return rejectWithValue("Something went wrong..");

      return videoId;
    } catch (error) {
      return error;
    }
  }
);

export const fetchVideo = createAsyncThunk<
  IVideo,
  string,
  { state: RootState }
>("videos/fetchVideo", async (videoId, { rejectWithValue }) => {
  try {
    const response = await fetch(`${envConfig.baseApi}/videos/${videoId}`);

    if (!response.ok) return rejectWithValue("Something went wrong..");

    const data: Video = await response.json();

    return data;
  } catch (error) {
    return rejectWithValue("Failed to fetch video");
  }
});

export const fetchVideoClips = createAsyncThunk(
  "videos/fetchVideoClips",
  async (videoId, { getState, rejectWithValue }) => {
    try {
      const response = await authorizedFetch(
        `${envConfig.baseApi}/videos/${videoId}/clips`,
        getState()
      );

      const data: IVideoClip[] = await response.json();

      if (!response.ok) return rejectWithValue(data);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchVideos = createAsyncThunk(
  "videos/fetchVideos",
  async (_, { getState, rejectWithValue }) => {
    try {
      const response = await authorizedFetch(
        `${envConfig.baseApi}/videos`,
        getState()
      );

      const data: Video[] = await response.json();

      if (!response.ok) rejectWithValue(data);

      return data;
    } catch (error) {
      return error;
    }
  }
);

const videosSlice = createSlice({
  name: "videos",
  initialState,
  reducers: {
    addVideo: (state, action: PayloadAction<IVideo>) => {
      const videoExists = state.videos.some(
        (video) => video.id === action.payload.id
      );
      if (!videoExists) {
        state.videos.push({
          ...action.payload,
          processing_status: ProcessingStatus.PROCESSING,
          subtitles: [],
        });
      }
    },
    setVideos: (state, action: PayloadAction<Video[]>) => {
      state.videos = action.payload;
    },
    updateVideo: (
      state,
      action: PayloadAction<{
        data: Video;
      }>
    ) => {
      const updatedVideo: Video = action.payload.data;

      const video: Video = state.videos.find((v) => v.id === updatedVideo.id);

      if (video) {
        video.processing_status = updatedVideo.processing_status;
        video.thumbnail_path = updatedVideo.thumbnail_path;
        video.processing_error = updatedVideo.processing_error;
        video.media_metadata = updatedVideo.media_metadata;
      }
    },
    updateVideoClip: (
      state,
      action: PayloadAction<{
        data: IVideoClip;
      }>
    ) => {
      const updatedClip: IVideoClip = action.payload.data;

      const video: Video = state.videos.find(
        (v) => v.id === updatedClip.video_id
      );

      if (video && video.clips && video.clips.length) {
        video.clips = video.clips.map((clip: IVideoClip) => {
          if (clip.id === updatedClip.id) {
            return { ...updatedClip };
          }

          return clip;
        });
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSubtitles.fulfilled, (state, action) => {
        const { video_id, subtitles, words_per_line } = action.payload;

        state.videos = state.videos.map((v) => {
          if (v.id === video_id) {
            return {
              ...v,
              subtitles,
              processing_status: ProcessingStatus.SUCCESS,
              subtitles_words_per_line: words_per_line,
            };
          }
          return v;
        });
      })
      .addCase(fetchVideos.pending, (state, action) => {
        state.loadingStatus = "loading";
      })
      .addCase(fetchVideos.fulfilled, (state, action) => {
        state.loadingStatus = "succeeded";
        state.videos = action.payload;
      })
      .addCase(fetchVideos.rejected, (state, action) => {
        state.loadingStatus = "failed";
        state.error = action.error.message || "Failed to fetch videos";
      })
      .addCase(fetchVideoClips.fulfilled, (state, action) => {
        const clips: IVideoClip[] = action.payload;

        if (clips && clips.length) {
          const videoId: string = action.payload[0].video_id;

          state.videos = state.videos.map((v) => {
            if (v.id === videoId) {
              return { ...v, clips };
            }

            return v;
          });
        }
      })
      .addCase(addNewVideo.fulfilled, (state, action) => {
        state.videos.unshift(action.payload);
      })
      .addCase(deleteVideo.fulfilled, (state, action) => {
        state.videos = state.videos.filter((v) => v.id !== action.payload);
      });
    builder.addMatcher(
      apiSlice.endpoints.getVideos.matchFulfilled,
      (state, action) => {
        state.videos = action.payload;
        state.loadingStatus = "succeeded";
      }
    );
  },
});

export const { addVideo, updateVideo, updateVideoClip } = videosSlice.actions;

export const selectVideoById = (state: RootState, videoId: string) =>
  state.videos.videos.find((v) => v.id === videoId);

export const selectVideos = (state: RootState) => state.videos.videos;

export const selectVideoClips = (state: RootState, videoId: string) =>
  state.videos.videos.find((v) => v.id === videoId)?.clips || [];
export default videosSlice.reducer;
