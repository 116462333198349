import { Player, PlayerRef } from "@remotion/player";
import { MyComposition } from "./../../remotion/Composition";
import { forwardRef, useImperativeHandle, useRef } from "react";
import { IClipConfig } from "../../remotion/types";
import { useSelector } from "react-redux";

import { RootState } from "../../store";

const Remotion = forwardRef(
  (
    props: {
      clipConfig: Partial<IClipConfig>;
    },
    ref
  ) => {
    const playerRef = useRef<PlayerRef>(null);

    const [segments, subtitles] = useSelector((state: RootState) => [
      state.clipConfig.segments,
      state.clipConfig.subtitles,
    ]);

    useImperativeHandle(
      ref,
      () => ({
        toggleVideo() {
          playerRef.current?.toggle();
        },
        seekTo(frame: number) {
          playerRef.current?.seekTo(frame);
        },
      }),
      [props]
    );

    return (
      props.clipConfig && (
        <Player
          ref={playerRef}
          component={MyComposition}
          inputProps={{
            props: {
              ...props.clipConfig,
              segments,
              subtitles,
              scale: playerRef.current?.getScale(),
            },
          }}
          durationInFrames={Math.floor(
            props.clipConfig.sourceVideo.media_metadata.duration_seconds *
              props.clipConfig.sourceVideo.media_metadata.fps
          )}
          compositionWidth={props.clipConfig.sourceVideo.media_metadata.width}
          compositionHeight={props.clipConfig.sourceVideo.media_metadata.height}
          style={{
            width: props.clipConfig.compositionDimensions?.width,
            height: props.clipConfig.compositionDimensions?.height,
            margin: "0 auto",
          }}
          fps={props.clipConfig.sourceVideo.media_metadata.fps}
        />
      )
    );
  }
);

Remotion.displayName = "Remotion";

export default Remotion;
