import { Modal, Button, createStyles, LoadingOverlay } from "@mantine/core";
import VFIconComponent from "./icon/vf-icon";

const useStyles = createStyles(() => ({
  title: {
    fontWeight: "bold",
    fontSize: "24px",
    textAlign: "center",
    margin: "0 auto",
  },
  body: {
    overflow: "hidden",
  },
  root: {
    textAlign: "center",
    marginTop: 35,
    minHeight: 100,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  inner: {
    ".mantine-Paper-root": {
      borderRadius: 10,
    },
  },
}));

interface DeleteEntityModalProps {
  isOpen: boolean;
  onClose: () => void;
  entityRepresentation: string;
  handleConfirmDelete: () => void;
  isLoading: boolean;
}
const DeleteEntityModal: React.FC<DeleteEntityModalProps> = ({
  isOpen,
  onClose,
  entityRepresentation,
  handleConfirmDelete,
  isLoading,
}) => {
  const { classes } = useStyles();

  return (
    <Modal
      withCloseButton={false}
      centered
      transition="fade"
      classNames={{
        root: classes.root,
        title: classes.title,
        body: classes.body,
        inner: classes.inner,
      }}
      transitionDuration={650}
      overlayOpacity={0.55}
      overlayBlur={3}
      size={650}
      padding={0}
      opened={isOpen}
      onClose={() => onClose()}
    >
      <LoadingOverlay
        visible={isLoading}
        loaderProps={{ size: "lg", variant: "dots" }}
      />
      <div
        className="style-header"
        style={{
          height: "50px",
          width: "100%",
          backgroundColor: "#29b1a1",
          borderTopRightRadius: "8px",
          borderTopLeftRadius: "8px",
          padding: "0 15px",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <VFIconComponent
          style={{ cursor: "pointer" }}
          type="close"
          onClick={() => onClose()}
          size={30}
        />
      </div>
      <div className="content">
        <div
          className="icon-holder"
          style={{
            textAlign: "center",
          }}
        >
          <VFIconComponent
            type={"error"}
            size={70}
            style={{
              display: "block",
              margin: "0 auto 20px auto",
            }}
          />
        </div>
        <div className="modal-header">
          <h1
            className="title"
            style={{
              margin: "0 0 20px 0",
            }}
          >{`Delete ${entityRepresentation}`}</h1>
          <p
            className="subtitle"
            style={{
              color: "#666666",
              marginBottom: "30px",
            }}
          >
            {`Are you sure you want to delete this ${entityRepresentation}?`}
          </p>
        </div>
        <div
          className="modal-actions"
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "20px",
            marginTop: "30px",
          }}
        >
          <Button
            style={{
              minWidth: "120px",
            }}
            className="vf-button-filled"
            color="red"
            onClick={handleConfirmDelete}
            size="lg"
            variant="outline"
          >
            Delete
          </Button>
          <Button
            style={{
              minWidth: "120px",
            }}
            className="vf-button-outlined"
            onClick={() => onClose()}
            size="lg"
            variant="outline"
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

DeleteEntityModal.displayName = "DeleteEntityModal";

export default DeleteEntityModal;
