import { createSlice } from "@reduxjs/toolkit";
import { ModalConfig } from "./types";

type ModalState = {
  modals: ModalConfig[];
};

const initialState: ModalState = {
  modals: [],
};

const modalSlice = createSlice({
  name: "modal",
  initialState: initialState,
  reducers: {
    openModal: (state, action) => {
      state.modals.push(action.payload);
    },
    closeModal: (state) => {
      state.modals.pop();
    },
    closeAllModals: (state) => {
      state.modals = [];
    },
  },
});

export const { openModal, closeModal, closeAllModals } = modalSlice.actions;

export default modalSlice.reducer;
