import React from "react";
import { Button, Modal, createStyles } from "@mantine/core";
import { useDispatch } from "react-redux";
import "./ModalWrapper.scss";
import { ModalAction, ModalConfig } from "../../../features/modal/types";
import { closeModal } from "../../../features/modal/modalSlice";
import VFIconComponent from "../../icon/vf-icon";

const useStyles = createStyles(() => ({
  title: {
    fontWeight: "bold",
    fontSize: "24px",
    textAlign: "center",
    margin: "0 auto",
  },
  body: {
    overflow: "hidden",
  },
  root: {
    textAlign: "center",
    marginTop: 35,
    minHeight: 100,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  inner: {
    ".mantine-Paper-root": {
      borderRadius: 10,
    },
  },
}));

export const ModalWrapper: React.FC<{
  config: ModalConfig;
  isLast: boolean;
}> = ({ config, isLast }) => {
  const dispatch = useDispatch();
  const { classes } = useStyles();

  const handleClose = () => {
    config.onClose?.();
    dispatch(closeModal());
  };

  const handleAction = (action: ModalAction) => {
    action.onClick();
  };

  return (
    <Modal
      opened={isLast}
      onClose={handleClose}
      withCloseButton={false}
      closeOnClickOutside={config.closable}
      closeOnEscape={config.closable}
      centered
      transition="fade"
      transitionDuration={500}
      transitionTimingFunction="ease"
      classNames={{
        root: classes.root,
        title: classes.title,
        body: classes.body,
        inner: classes.inner,
      }}
      overlayOpacity={0.55}
      overlayBlur={3}
      size={650}
      padding={0}
    >
      <div className="style-header">
        {config.closable && (
          <VFIconComponent
            className="close-button"
            type="close"
            onClick={handleClose}
            size={30}
          />
        )}
      </div>
      <div className="content">
        {config.iconConfig && (
          <div className="icon-holder">
            <VFIconComponent
              type={config.iconConfig.type}
              size={config.iconConfig.size || 70}
              className="modal-icon"
            />
          </div>
        )}
        <div className="modal-header">
          <h1 className="title">{config.title}</h1>
          {config.subtitle && <p className="subtitle">{config.subtitle}</p>}
        </div>
        <div className="modal-actions">
          {config.actions.map((action, actionIndex) => (
            <Button
              key={actionIndex}
              size={action.size || "md"}
              className={`vf-button-${
                action.variant === "outline" ? "outlined" : "filled"
              }`}
              variant={action.variant === "outline" ? "outline" : "default"}
              onClick={() => handleAction(action)}
            >
              {action.label}
            </Button>
          ))}
        </div>
        {config.footer && <div className="modal-footer">{config.footer}</div>}
      </div>
    </Modal>
  );
};
