import { Col, Modal, Grid, createStyles } from "@mantine/core";
import StripePricingTable from "./StripePricingTable";
import { useGetCustomerPortalUrlMutation } from "../features/api/apiSlice";
import envConfig from "../envConfig";
import { RootState } from "../store";
import { useSelector } from "react-redux";

const useStyles = createStyles((theme) => ({
  title: {
    fontWeight: "bold",
    fontSize: "24px",
    textAlign: "center",
    margin: "0 auto",
  },
  body: {
    padding: "40px",
  },
  root: {
    textAlign: "center",
    marginTop: 35,
    minHeight: 100,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
}));

const SubscriptionModal: React.FC<{}> = ({}) => {
  const { classes, theme } = useStyles();
  const [getCustomerPortalUrl, { data: customerPortal }] =
    useGetCustomerPortalUrlMutation();
  const { userData } = useSelector((state: RootState) => state.auth);

  return (
    <Modal
      classNames={{
        root: classes.root,
        title: classes.title,
        body: classes.body,
      }}
      opened={true}
      onClose={() => {}}
      padding={"lg"}
      centered={true}
      overlayOpacity={0.55}
      overlayBlur={3}
      size={"80%"}
      closeOnClickOutside={false}
      closeOnEscape={false}
      withCloseButton={false}
    >
      <Grid gutter="md">
        <Col span={12}>
          <h1>Subscription Plans</h1>
          <p style={{ color: "#666666" }}>
            Please select a subscription plan to activate your account
          </p>
          <StripePricingTable
            pricingTableId={envConfig.stripePricingTableId}
            publishableKey={envConfig.stripePublishableKey}
            clientReferenceId={userData?.id}
            customerEmail={null}
          />
          {/*Setting customerEmail to null because checkout page wont allow to change it this could cause issue if*/}
          {/*PayPal is used for payment and the customer registered with a different email address than the PayPal ono*/}
        </Col>
      </Grid>
    </Modal>
  );
};

export default SubscriptionModal;
