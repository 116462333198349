import React from "react";
import ArrowDownIcon from "../icons/ArrowDownIcon";
import ErrorIcon from "../icons/ErrorIcon";
import SuccessIcon from "../icons/SuccessIcon";
import UploadIcon from "../icons/UploadIcon";
import TextIcon from "../icons/TextIcon";
import ClockIcon from "../icons/ClockIcon";
import AspectRatioIcon from "../icons/AspectRatioIcon";
import SubtitlesIcon from "../icons/SubtitlesIcon";
import InfoIcon from "../icons/InfoIcon";
import AddVideoIcon from "../icons/AddVideoIcon";
import CloseIcon from "../icons/CloseIcon";
import LockerIcon from "../icons/LockerIcon";
import RocketIcon from "../icons/RocketIcon";
import WarningIcon from "../icons/WarningIcon";
import YoutubeTextIcon from "../icons/YoutubeTextIcon";
import UploadIcon2 from "../icons/UploadIcon2";
import VideoFileIcon from "../icons/VideoFileIcon";
import DeleteFilledIcon from "../icons/DeleteFilledIcon";
import DeleteOutlineIcon from "../icons/DeleteOutlineIcon";

import { IconType } from "../icons/types";

const iconMap: Record<IconType, React.ComponentType<any>> = {
  success: SuccessIcon,
  error: ErrorIcon,
  upload: UploadIcon,
  "arrow-down": ArrowDownIcon,
  text: TextIcon,
  clock: ClockIcon,
  "aspect-ratio": AspectRatioIcon,
  subtitles: SubtitlesIcon,
  info: InfoIcon,
  "add-video": AddVideoIcon,
  close: CloseIcon,
  locker: LockerIcon,
  rocket: RocketIcon,
  warning: WarningIcon,
  "youtube-text": YoutubeTextIcon,
  "upload-2": UploadIcon2,
  "video-file": VideoFileIcon,
  "delete-filled": DeleteFilledIcon,
  "delete-outline": DeleteOutlineIcon,
};

interface VFIconComponentProps {
  type: IconType;
  backgroundColor?: string;
  color?: string;
  className?: string;
  size?: number;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  style?: React.CSSProperties;
}

const VFIconComponent: React.FC<VFIconComponentProps> = ({
  type,
  backgroundColor = "transparent",
  color,
  className,
  size = 30,
  onClick,
  style,
  ...rest
}) => {
  const IconComponent = iconMap[type];

  return (
    <div
      className={className}
      style={{
        backgroundColor: backgroundColor,
        display: "inline-block",
        width: size,
        height: size,
        ...style,
      }}
      onClick={onClick}
    >
      <IconComponent color={color} size={size} {...rest} />
    </div>
  );
};

VFIconComponent.displayName = "VFIconComponent";

export default VFIconComponent;
