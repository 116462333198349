import { Text, createStyles, Group, Loader } from "@mantine/core";
import { ICaption, IToolOption, IToolTab } from "../../types";
import "./CaptionsOptions.scss";
import { forwardRef, memo, useCallback, useEffect, useState } from "react";
import CaptionsTabs from "../captions-tabs/CaptionsTabs";
import { useDispatch } from "react-redux";
import { ISubtitle, ISubtitlesConfig } from "../../remotion/types";
import { setSubtitles } from "../../features/clipConfig/clipConfigSlice";
import { DEFAULT_SUBTITLES_WORDS_PER_LINE } from "../../constants";

const useStyles = createStyles(() => ({
  root: {
    margin: "10px 0",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  label: {
    width: "40%",
    textAlign: "left",
  },
  input: {
    width: "100%",
    textAlign: "center",
    paddingLeft: "0 !important",
    "&:focus": {
      borderColor: "#29B1A1",
    },
  },
  wrapper: {
    width: "150px",
  },
  switchTrack: {
    "&input:checked": {
      backgroundColor: "#29B1A1",
      borderColor: "#29B1A1",
    },
  },
  switchInput: {
    "&:checked+.mantine-Switch-track": {
      backgroundColor: "#29B1A1",
      borderColor: "#29B1A1",
    },
  },
  textAreaRoot: {
    textAlign: "left",
  },
  radioButtonGroupRoot: {
    "[role=radiogroup]": {
      justifyContent: "space-around",
    },
  },
  tabsRoot: {
    marginBottom: "20px",
  },
  selectItem: {
    "&[data-selected]": {
      background: "#29b1a1",
      "&:hover": {
        background: "#29b1a1",
      },
    },
  },
  numberInputRightSection: {
    button: {
      border: "none",
    },
  },
}));

const captionsConfiguration: any = {
  enabled: false,
  key: "captions",
  title: "Subtitles",
  showFor: "both",
  tabs: [
    {
      title: "Styles",
      options: [
        {
          showFor: "both",
          key: "captions_size",
          type: "number",
          //! TODO: number limits or other validations
          label: "Size",
          defaultValue: 70,
          minValue: 10,
          value: 70,
          step: 1,
          placeholder: "",
        },
        {
          showFor: "both",
          key: "captions_font_name",
          type: "select",
          items: null,
          label: "Font",
          defaultValue: "Futura",
          value: "Futura",
          placeholder: "",
        },
        {
          showFor: "both",
          key: "subtitles_template",
          type: "select",
          items: null,
          label: "Font",
          defaultValue: "Arimo",
          value: "Arimo",
          placeholder: "",
        },
        {
          showFor: "both",
          key: "captions_color",
          type: "color-picker",
          label: "Color",
          defaultValue: "#ffffff",
          value: "#ffffff",
          placeholder: "",
        },
        {
          showFor: "both",
          key: "captions_stroke_color",
          type: "color-picker",
          label: "Stroke color",
          defaultValue: "#000000",
          value: "#000000",
          placeholder: "",
        },
        {
          showFor: "both",
          key: "captions_stroke_width",
          type: "number",
          label: "Stroke width",
          defaultValue: 2,
          minValue: 0,
          value: 2,
          step: 1,
          placeholder: "",
        },
        {
          showFor: "project",
          key: "captions_words_per_line",
          type: "number",
          label: "Words per line",
          step: 1,
          defaultValue: DEFAULT_SUBTITLES_WORDS_PER_LINE,
          minValue: 1,
          value: 3,
          placeholder: "",
        },
        {
          showFor: "both",
          key: "captions_highlight_color",
          type: "color-picker",
          label: "Highlight color",
          defaultValue: "#FEF91A",
          value: "#FEF91A",
          placeholder: "",
        },
        {
          showFor: "both",
          key: "captions_highlight_background_color",
          type: "color-picker",
          label: "Highlight background color",
          defaultValue: "#000000",
          value: "#000000",
          placeholder: "",
        },
      ],
      showFor: "both",
    },
    {
      title: "Edit Subtitles",
      options: [
        // ...
      ],
      showFor: "edit",
    },
  ],
};

const SelectItem = memo(
  forwardRef<HTMLDivElement, ItemProps>(
    ({ fontName, label, styles, value, ...others }: ItemProps, ref) => (
      <div key={fontName} ref={ref} {...others}>
        <Group noWrap>
          <div key={fontName}>
            <Text key={fontName} style={{ ...styles }}>
              Subtitle
            </Text>
            <Text
              key={fontName}
              style={{
                ...styles,
                background: styles.highlightBackgroundColor,
                color: styles.highlightColor,
                borderRadius: "15px",
                padding: "0 10px",
              }}
            >
              highlight
            </Text>
          </div>
        </Group>
      </div>
    )
  )
);

SelectItem.displayName = "SelectItem";

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  fontName: string;
  label: string;
  value: string;
  styles: React.CSSProperties;
}

interface CaptionsOptionsProps {
  isEdit: boolean;
  videoId: string;
  subtitles: ISubtitle[] | undefined;
  videoPlayerRef: any;
}

const CaptionsOptions = memo(
  ({ isEdit, videoId, subtitles, videoPlayerRef }: CaptionsOptionsProps) => {
    const [captionsConfig, setCaptionsConfig] = useState(captionsConfiguration);
    const { classes } = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const [subtitlesPopulated, setSubtitlesPopulated] = useState(false);

    useEffect(() => {
      if (isEdit) {
        populateEditToolsValues();
      }
    }, [isEdit]);

    useEffect(() => {
      if (subtitles && subtitles.length) {
        populateSubtitlesInTabs();
        setIsLoading(false);
      } else {
        // check video subtitles status
        // dispatch(fetchSubtitles({ video_id: videoId, words_per_line: 3 }));
      }
    }, [subtitles]);

    const subtitlesChangeHandler = useCallback((captions) => {
      dispatch(setSubtitles({ items: captions }));
    }, []);

    const loaderContent = (
      <div
        style={{
          display: "block",
          width: "450px",
        }}
      >
        Subtitles are loading...
        <Loader />
      </div>
    );

    const renderContent = () => {
      if (!captionsConfig || (!captionsConfig.options && !captionsConfig.tabs))
        return;

      return (
        <div>
          {captionsConfig.tabs && (
            <div className="tabs-holder">
              <CaptionsTabs
                tabs={captionsConfig.tabs}
                shouldShowObject={shouldShowObject}
                handleSubtitlesChange={subtitlesChangeHandler}
                videoPlayerRef={videoPlayerRef}
                videoId={videoId}
              ></CaptionsTabs>
            </div>
          )}
        </div>
      );
    };

    const shouldShowObject = (object: any): boolean => {
      // if (!object) return false;

      // return (
      //   object.showFor === "both" ||
      //   !object.showFor ||
      //   (object.showFor === "project" && !isEdit) ||
      //   (object.showFor === "edit" && isEdit)
      // );

      return true;
    };

    const populateEditToolsValues = () => {
      if (!edit) return;

      const capsConf = edit.config.captions_config;

      if (!capsConf || !captionsConfig) {
        return;
      }

      const updateOptionValue = (options: IToolOption[]) => {
        if (!options || !options.length) return;

        options.forEach((option: IToolOption) => {
          if (option.key) {
            let key = option.key.replace("captions_", "");

            // override font_name key because of inconsistency
            if (key === "font_name") key = "font";

            if (capsConf.hasOwnProperty(key)) {
              option.value = capsConf[key];
            }
          }
        });
      };

      if (captionsConfig.options) {
        updateOptionValue(captionsConfig.options);
      }

      if (captionsConfig.tabs) {
        captionsConfig.tabs.forEach((tab: IToolTab) => {
          if (tab.options) {
            updateOptionValue(tab.options as IToolOption[]);

            if (tab.title === "Edit Subtitles") {
              const mappedCaptions: Array<any> = [];

              edit.captions.map((caption: ICaption) => {
                mappedCaptions.push({
                  ...caption,
                  type: "caption",
                });
              });
              // todo: static for now, should be with id or key
              tab.options = mappedCaptions;
            }
          }
        });
      }

      // set captions enabled
      // captionsConfig.enabled = true;

      setTimeout(() => {
        setCaptionsConfig({ ...captionsConfig });
      }, 10);
    };

    const getToolOption = (toolKey: string, optionKey: string) => {
      if (captionsConfig.options) {
        // tool with options
        return captionsConfig.options.find(
          (option: any) => option.key === optionKey
        );
      } else if (captionsConfig.tabs) {
        // tool with tabs
        let option: any;

        captionsConfig.tabs.forEach((tab: any) => {
          if (tab.options && !option) {
            option = tab.options.find(
              (option: any) => option.key === optionKey
            );
          }
        });

        return option;
      }
    };

    const getCaptionsConfig = (): ISubtitlesConfig => {
      return {
        font: getToolOption("captions", "captions_font_name").value,
        size: getToolOption("captions", "captions_size").value,
        color: getToolOption("captions", "captions_color").value,
        strokeColor: getToolOption("captions", "captions_stroke_color").value,
        strokeWidth: getToolOption("captions", "captions_stroke_width").value,
        highlightColor: getToolOption("captions", "captions_highlight_color")
          .value,
        highlightBackgroundColor: getToolOption(
          "captions",
          "captions_highlight_background_color"
        ).value,
      };
    };

    const populateSubtitlesInTabs = () => {
      if (!captionsConfig.tabs) return;

      captionsConfig.tabs = captionsConfig.tabs.map((tab: IToolTab) => {
        if (tab.options && tab.title === "Edit Subtitles") {
          const mappedCaptions: Array<ISubtitle & { type: string }> =
            subtitles?.map((subtitle: ISubtitle) => ({
              ...subtitle,
              type: "caption",
            })) || [];

          return {
            ...tab,
            options: mappedCaptions,
          };
        }

        // Return the original tab if no changes are needed
        return tab;
      });

      setCaptionsConfig({ ...captionsConfig });

      setSubtitlesPopulated(true);
    };

    return <div>{!subtitlesPopulated ? loaderContent : renderContent()}</div>;
  }
);

CaptionsOptions.displayName = "CaptionsOptions";

export default CaptionsOptions;
