import { IconType } from "../../components/icons/types";

export enum ModalType {
  DEFAULT = "DEFAULT",
  ERROR = "ERROR",
  CONFIRM_EMAIL = "CONFIRM_EMAIL",
  USAGE_QUOTA = "USAGE_QUOTA",
  SUBSCRIPTION = "SUBSCRIPTION",
  CHANGE_PASSWORD = "CHANGE_PASSWORD",
}

export type ModalAction = {
  label: string;
  onClick: () => void;
  variant?: "filled" | "outline";
  size?: "sm" | "md" | "lg" | "xl";
};

export type ModalIcon = {
  type: IconType;
  color?: string;
  size?: number;
  style?: React.CSSProperties;
};

export type ModalConfig = {
  type?: ModalType;
  iconConfig?: ModalIcon;
  title?: string;
  subtitle?: string;
  actions?: ModalAction[];
  footer?: React.ReactNode;
  closable?: boolean;
  onClose?: () => void;
};
