import vidfastLogo from "./../assets/images/vidfast-logo.svg";
import "./Header.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { UserDropdown } from "./user-dropdown/UserDropdown";
import { memo, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

const Header: React.FC = memo(() => {
  const navigate = useNavigate();

  const initialFetchRef = useRef(false);

  const location = useLocation();

  const pathsWithoutHeader = [
    "/projects/:projectId/clips/:editId/edit",
    "/projects/:projectId/edit",
  ];

  const isPathMatched = (path: string) => {
    const regexPath =
      "^" +
      path
        .replace(/:[^\s/]+/g, "([\\w-]+)") // Replace dynamic params with regex
        .replace(/\//g, "\\/") + // Escape slashes
      "$";

    const regex = new RegExp(regexPath);
    return location.pathname.match(regex);
  };

  const showHeader = !pathsWithoutHeader.some((path) => isPathMatched(path));

  const dispatch = useDispatch();

  useEffect(() => {
    if (!initialFetchRef.current) {
      // Dispatch action to connect SSE with navigate function
      dispatch({ type: "CONNECT_SSE", payload: { navigate } });

      initialFetchRef.current = true;
    }
  }, []);

  return (
    showHeader && (
      <header className="header">
        <div className="header-content">
          {/* <div className="menu">
            <ul className="sidebar-menu">
              <li>
                <NavLink
                  to="/projects"
                  className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""
                  }
                >
                  Projects
                </NavLink>
              </li>
            </ul>
          </div> */}
          <div className="header-logo" onClick={() => navigate("/")}>
            <img src={vidfastLogo} alt="Logo" />
          </div>
          <div className="user-dropdown-holder">
            <UserDropdown />
          </div>
        </div>
      </header>
    )
  );
});

Header.displayName = "Header";

export default Header;
