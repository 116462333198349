import React from "react";
import { useSelector } from "react-redux";
import { ModalWrapper } from "../../components/modals/modal-wrapper/ModalWrapper";
import { RootState } from "../../store";
import { ModalConfig, ModalType } from "./types";
import ConfirmEmailModal from "../../components/ConfirmEmailModal";
import UsageQuotaModal from "../../components/UsageQuotaModal";
import SubscriptionModal from "../../components/SubscriptionModal";
import PasswordChangeForm from "../../components/PasswordChangeComponent";

const MODAL_COMPONENTS = {
  [ModalType.DEFAULT]: ModalWrapper,
  [ModalType.CONFIRM_EMAIL]: ConfirmEmailModal,
  [ModalType.USAGE_QUOTA]: UsageQuotaModal,
  [ModalType.SUBSCRIPTION]: SubscriptionModal,
  [ModalType.CHANGE_PASSWORD]: PasswordChangeForm,
};

export const ModalProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const modals = useSelector((state: RootState) => state.modal.modals);

  return (
    <>
      {children}
      {modals.map((modal: ModalConfig, index) => {
        const ModalComponent =
          MODAL_COMPONENTS[modal.type || ModalType.DEFAULT];

        return (
          <ModalComponent
            key={index}
            config={modal}
            isLast={index === modals.length - 1}
          />
        );
      })}
    </>
  );
};
