import { useDispatch } from "react-redux";
import {
  openModal,
  closeModal,
  closeAllModals,
} from "../features/modal/modalSlice";
import { ModalConfig } from "../features/modal/types";

export const useModal = () => {
  const dispatch = useDispatch();

  return {
    openModal: (config: ModalConfig) => {
      dispatch(openModal(config));
    },
    closeModal: () => dispatch(closeModal()),
    closeAllModals: () => dispatch(closeAllModals()),
  };
};
