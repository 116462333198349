import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { getUserData, registerUser, userLogin } from "./authActions";
import { Cookies } from "react-cookie";
import { IUserData } from "../../types";
import { clarity } from "react-microsoft-clarity";

interface AuthSliceState {
  userData: IUserData | null;
  userToken: string | null;
  isAuthenticated: boolean;
  emailConfirmed: boolean;
  subscriptionLevel: number | null;
  isLoading: boolean;
  error: string | null;
}

const cookies = new Cookies();

let cookieValue = cookies.get("user_auth");

if (cookieValue === "null") {
  cookies.remove("user_auth");
  cookieValue = null;
}

const userToken = cookieValue ? cookieValue : null;

const initialState: AuthSliceState = {
  userData: null,
  userToken,
  isAuthenticated: false,
  emailConfirmed: false,
  subscriptionLevel: null,
  isLoading: false,
  error: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      cookies.remove("user_auth");
      state.isLoading = false;
      state.userData = null;
      state.userToken = null;
      state.error = null;
      state.isAuthenticated = false;
    },
    setUserData: (state, { payload }) => {
      state.userData = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userLogin.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(userLogin.fulfilled, (state, { payload }) => {
      if (payload && payload.access_token) {
        state.isLoading = false;
        state.userToken = payload.access_token;
        state.isAuthenticated = true;
        cookies.set("user_auth", payload.access_token, { path: "/" });
      }
    });
    builder.addCase(userLogin.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload as any;
      state.isAuthenticated = false;
    });
    builder.addCase(registerUser.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(registerUser.fulfilled, (state, { payload }) => {
      if (payload && payload.access_token) {
        state.isLoading = false;
        state.userToken = payload.access_token;
        state.isAuthenticated = true;
        cookies.set("user_auth", payload.access_token, { path: "/" });
      }
    });
    builder.addCase(registerUser.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload as any;
      state.isAuthenticated = false;
    });
    builder.addCase(getUserData.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(
      getUserData.fulfilled,
      (state, action: PayloadAction<IUserData>) => {
        const userData: IUserData = action.payload;

        state.userData = userData;
        state.emailConfirmed = userData.email_confirmed;
        state.subscriptionLevel = userData.subscription_level;
        state.isAuthenticated = true;
        state.isLoading = false;

        if (clarity.hasStarted()) {
          clarity.identify(userData.id, {
            id: userData.id,
            email: userData.email,
            subscription_level: userData.subscription_level,
            created_at: userData.created_at,
          });
        }
      }
    );
    builder.addCase(
      getUserData.rejected,
      (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload;
      }
    );
  },
});

export const { logout, setUserData } = authSlice.actions;
export default authSlice.reducer;
