import { useState, useEffect, useRef, memo, useCallback } from "react";
import { IVideoSegment } from "../../remotion/types";

const MIN_DURATION_SECONDS = 1; // Minimum duration of a segment in seconds

interface SegmentsEditorProps {
  items: IVideoSegment[] & { active: boolean };
  pixelsPerSecond: number;
  videoDuration: number;
  type: string;
  onItemsUpdated: (updatedItems: IVideoSegment[]) => void;
  onTimeUpdated: (time: number) => void;
  scrollLeft: number;
  canvasWidth: number;
}

const SegmentsEditor: React.FC<SegmentsEditorProps> = memo(
  (
    {
      items,
      pixelsPerSecond,
      videoDuration,
      type,
      onItemsUpdated,
      onTimeUpdated,
      scrollLeft,
      canvasWidth,
    },
    ref
  ) => {
    const [itemsData, setItemsData] = useState(items);
    const canvasRef = useRef(null);
    const scrollLeftRef = useRef(scrollLeft);
    const pixelsPerSecondRef = useRef(pixelsPerSecond);
    const itemsDataRef = useRef(itemsData);

    useEffect(() => {
      setItemsData(items);
      itemsDataRef.current = items;

      drawSegments();
    }, [items]);

    useEffect(() => {
      scrollLeftRef.current = scrollLeft;
      drawSegments();
    }, [scrollLeft]);

    useEffect(() => {
      pixelsPerSecondRef.current = pixelsPerSecond;
      drawSegments();
    }, [pixelsPerSecond]);

    useEffect(() => {
      const canvas = canvasRef.current;
      drawSegments();
      canvas.addEventListener("mousedown", handleMouseDown);
      canvas.addEventListener("mousemove", handleMouseMove);
      canvas.addEventListener("mouseup", handleMouseUp);

      return () => {
        canvas.removeEventListener("mousedown", handleMouseDown);
        canvas.removeEventListener("mousemove", handleMouseMove);
        canvas.removeEventListener("mouseup", handleMouseUp);
      };
    }, [itemsData]);

    const RESIZE_HANDLE_WIDTH = 10; // Width of the resize handle

    const handleMouseDown = useCallback(
      (event) => {
        const rect = canvasRef.current.getBoundingClientRect();
        const x = event.clientX - rect.left + scrollLeftRef.current; // x position within the canvas, adjusted for scroll
        const y = event.clientY - rect.top; // y position within the canvas

        itemsData.forEach((item, index) => {
          const left = item.start * pixelsPerSecondRef.current;
          const right = item.end * pixelsPerSecondRef.current;

          // Check vertical position within segment height (assuming height is 35)
          if (y >= 0 && y <= 35) {
            if (x >= left && x <= left + RESIZE_HANDLE_WIDTH) {
              // Mouse is over left resize handle
              startResizing("left", index, x);
            } else if (x >= right - RESIZE_HANDLE_WIDTH && x <= right) {
              // Mouse is over right resize handle
              startResizing("right", index, x);
            }
          }
        });
      },
      [itemsData]
    );

    let resizingSegment = null;
    let initialMouseX = 0;

    const startResizing = (
      side: "left" | "right",
      index: number,
      mouseX: number
    ) => {
      initialMouseX = mouseX;
      resizingSegment = { index, side };
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    };

    const handleMouseMove = useCallback(
      (moveEvent: MouseEvent) => {
        if (resizingSegment) {
          const rect = canvasRef.current.getBoundingClientRect();
          const x = moveEvent.clientX - rect.left + scrollLeftRef.current; // Adjust with scroll

          const item = itemsData[resizingSegment.index];
          const deltaX = (x - initialMouseX) / pixelsPerSecondRef.current;

          let newStartTime = item.start;
          let newEndTime = item.end;
          let overlap = false;

          if (resizingSegment.side === "left") {
            newStartTime = Math.max(0, item.start + deltaX);
            newStartTime = Math.min(
              newStartTime,
              item.end - MIN_DURATION_SECONDS
            );
            overlap = itemsData.some(
              (other) =>
                other.text !== item.text &&
                newStartTime < other.end &&
                item.end > other.start
            );
          } else {
            newEndTime = Math.max(
              item.start + MIN_DURATION_SECONDS,
              item.end + deltaX
            );
            newEndTime = Math.min(newEndTime, videoDuration);
            overlap = itemsData.some(
              (other) =>
                other.text !== item.text &&
                newEndTime > other.start &&
                item.start < other.end
            );
          }

          newStartTime = +newStartTime.toFixed(3);
          newEndTime = +newEndTime.toFixed(3);

          if (!overlap && newEndTime - newStartTime >= MIN_DURATION_SECONDS) {
            handleResize(item.text, newStartTime, newEndTime);

            onTimeUpdated(
              resizingSegment.side === "left" ? newStartTime : newEndTime
            );
          }
        } else {
          // Handle hover effect for resize handles
          const rect = canvasRef.current.getBoundingClientRect();
          const x = moveEvent.clientX - rect.left + scrollLeftRef.current; // Adjust with scroll
          const y = moveEvent.clientY - rect.top;

          let onHandle = false;
          itemsData.forEach((item) => {
            const left = item.start * pixelsPerSecondRef.current;
            const right = item.end * pixelsPerSecondRef.current;

            if (y >= 5 && y <= 30) {
              if (
                (x >= left && x <= left + RESIZE_HANDLE_WIDTH) ||
                (x >= right - RESIZE_HANDLE_WIDTH && x <= right)
              ) {
                onHandle = true;
              }
            }
          });

          canvasRef.current.style.cursor = onHandle ? "pointer" : "default";
        }
      },
      [itemsData, scrollLeftRef, canvasRef]
    );

    const handleResize = (id, newStartTime, newEndTime) => {
      const updatedItems = itemsData.map((item) => {
        if (item.text === id) {
          return { ...item, start: newStartTime, end: newEndTime };
        }
        return item;
      });

      setItemsData(updatedItems);
      itemsDataRef.current = updatedItems;
    };

    const handleMouseUp = useCallback(() => {
      if (resizingSegment) {
        resizingSegment = null;
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);

        onItemsUpdated(itemsDataRef.current);
      }
    }, [itemsData, scrollLeftRef, canvasRef]);

    const drawSegments = () => {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");

      // Clear existing drawings
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      itemsData.forEach((item) => {
        drawSegment(item, ctx);
      });
    };

    const drawSegment = (item, ctx) => {
      const left = item.start * pixelsPerSecond - scrollLeftRef.current;
      const width = (item.end - item.start) * pixelsPerSecond;
      const borderRadius = 10; // Set border radius
      const handleRadius = 2; // Set border radius for resize handles
      const handleWidth = 4;
      const handleHeight = 20;
      const handleBorderRadius = 2; // Set border radius for resize handles
      const handleTop = 8;

      // Draw segment with border radius
      ctx.fillStyle = "#127fff";
      ctx.strokeStyle =
        itemsDataRef.current && itemsDataRef.current.length > 1 && item.active
          ? "#ff0000"
          : "#275993";
      ctx.lineWidth = 2;

      ctx.beginPath();
      ctx.moveTo(left + borderRadius, 2);
      ctx.lineTo(left + width - borderRadius, 2);
      ctx.quadraticCurveTo(left + width, 2, left + width, borderRadius);
      ctx.lineTo(left + width, 35 - borderRadius);
      ctx.quadraticCurveTo(left + width, 35, left + width - borderRadius, 35);
      ctx.lineTo(left + borderRadius, 35);
      ctx.quadraticCurveTo(left, 35, left, 35 - borderRadius);
      ctx.lineTo(left, borderRadius);
      ctx.quadraticCurveTo(left, 2, left + borderRadius, 2);
      ctx.closePath();
      ctx.fill();
      ctx.stroke();

      // Draw resize handles
      ctx.fillStyle = "#fff";

      // Function to draw rounded rectangle for handles
      const drawRoundedRect = (x, y, width, height, borderRadius) => {
        ctx.beginPath();
        ctx.moveTo(x + borderRadius, y);
        ctx.lineTo(x + width - borderRadius, y);
        ctx.quadraticCurveTo(x + width, y, x + width, y + borderRadius);
        ctx.lineTo(x + width, y + height - borderRadius);
        ctx.quadraticCurveTo(
          x + width,
          y + height,
          x + width - borderRadius,
          y + height
        );
        ctx.lineTo(x + borderRadius, y + height);
        ctx.quadraticCurveTo(x, y + height, x, y + height - borderRadius);
        ctx.lineTo(x, y + borderRadius);
        ctx.quadraticCurveTo(x, y, x + borderRadius, y);
        ctx.closePath();
        ctx.fill();
      };

      // Draw left resize handle with border radius
      drawRoundedRect(
        left + 5,
        handleTop,
        handleWidth,
        handleHeight,
        handleBorderRadius
      );

      // Draw right resize handle with border radius
      drawRoundedRect(
        left + width - 9,
        handleTop,
        handleWidth,
        handleHeight,
        handleBorderRadius
      );
    };

    return (
      <div className={`timeline-editor ${type}`}>
        <canvas ref={canvasRef} width={canvasWidth} height={40}></canvas>
      </div>
    );
  }
);

SegmentsEditor.displayName = "SegmentsEditor";

export default SegmentsEditor;
