import {
  Group,
  NumberInput,
  Select,
  createStyles,
  Text,
  ColorInput,
  Switch,
  Tooltip,
  Slider,
} from "@mantine/core";
import {
  Fragment,
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  selectSubtitles,
  setSubtitles,
} from "../../features/clipConfig/clipConfigSlice";
import {
  fetchSubtitles,
  selectVideoById,
} from "../../features/videos/videosSlice";
import { useDispatch, useSelector } from "react-redux";
import { FONTS, SUBTITLE_TEMPLATES } from "../../constants";
import { ISubtitlesConfig, IVideo } from "../../remotion/types";
import VFIconComponent from "../icon/vf-icon";
import { useErrorHandler } from "../../hooks/useErrorHandler";
import { RootState } from "../../store";
import { getSubtitleDefaultSize } from "../../utils";

const useStyles = createStyles(() => ({
  root: {
    margin: "10px 0",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  label: {
    width: "40%",
    textAlign: "left",
    fontSize: "16px",
  },
  input: {
    width: "100%",
    textAlign: "center",
    paddingLeft: "0 !important",
    "&:focus": {
      borderColor: "#29B1A1",
    },
  },
  wrapper: {
    width: "200px",
  },
  switchTrack: {
    "&input:checked": {
      backgroundColor: "#29B1A1",
      borderColor: "#29B1A1",
    },
  },
  switchInput: {
    "&:checked+.mantine-Switch-track": {
      backgroundColor: "#29B1A1",
      borderColor: "#29B1A1",
    },
  },
  textAreaRoot: {
    textAlign: "left",
  },
  radioButtonGroupRoot: {
    "[role=radiogroup]": {
      justifyContent: "space-around",
    },
  },
  tabsRoot: {
    marginBottom: "20px",
  },
  selectItem: {
    borderBottom: "1px solid #b8e6e0",
    backgroundColor: "#d1eeea",
    "&:hover": {
      backgroundColor: "#b8e6e0",
    },
    "&[data-selected]": {
      backgroundColor: "#29b1a1",
      "&:hover": {
        backgroundColor: "#29b1a1",
      },
    },
  },
  selectDropDown: {},
  itemsWrapper: {
    padding: 0,
  },
  numberInputRightSection: {
    button: {
      border: "none",
    },
  },
  sliderRoot: {
    width: "200px",
  },
  sliderBar: {
    backgroundColor: "#29b1a1",
  },
  sliderThumb: {
    borderColor: "#29b1a1",
  },
}));

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  font: string;
  label: string;
  value: ISubtitlesConfig;
  styles: React.CSSProperties;
  type: "template" | "font";
}

const SelectItem = memo(
  forwardRef<HTMLDivElement, ItemProps>(
    ({ font, label, styles, value, type, ...others }: ItemProps, ref) => (
      <div key={label} ref={ref} {...others}>
        <Group noWrap>
          {type === "template" && label !== "Custom" && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text style={{ ...styles, fontFamily: font }}>Subtitle</Text>
              <Text
                style={{
                  fontFamily: font,
                  background: styles.highlightBackgroundColor,
                  color: styles.highlightColor,
                  borderRadius: "15px",
                  padding: "0 5px",
                }}
              >
                highlight
              </Text>
            </div>
          )}
          {type === "template" && label === "Custom" && (
            <div>
              <Text>{label}</Text>
            </div>
          )}
          {type === "font" && (
            <div>
              <Text key={label} style={{ ...styles, fontFamily: font }}>
                {label}
              </Text>
            </div>
          )}
        </Group>
      </div>
    )
  )
);

SelectItem.displayName = "SelectItem";

const CUSTOM_TEMPLATE: ISubtitlesConfig = {
  templateName: "Custom",
  size: 70,
  font: "RobotoSlab",
  color: "#ffffff",
  strokeColor: "#000000",
  highlightColor: "#ffffff",
  highlightBackgroundColor: "#000000",
  stroke: true,
};

interface SubtitleStylesProps {
  videoId: string;
}

const SubtitleStyles = memo(({ videoId }: SubtitleStylesProps) => {
  const dispatch = useDispatch();

  const { classes } = useStyles();

  const { config: subtitlesConfig } = useSelector(selectSubtitles);

  const { handleError } = useErrorHandler();

  const video: IVideo = useSelector((state: RootState) => {
    return selectVideoById(state, videoId);
  });

  const subtitleDefaultSize: number = video
    ? getSubtitleDefaultSize(video.media_metadata.height)
    : 70;

  const [selectedTemplate, setSelectedTemplate] = useState<ISubtitlesConfig>({
    ...subtitlesConfig,
    size: subtitleDefaultSize,
  });

  const [strokeEnabled, setStrokeEnabled] = useState<boolean>(
    selectedTemplate.stroke
  );

  const selectedTemplateRef = useRef(selectedTemplate);

  useEffect(() => {
    selectedTemplateRef.current = selectedTemplate;
  }, [selectedTemplate]);

  const handleInputChange = useCallback(
    async (key: string, value: any) => {
      if (key === "wordsPerLine") {
        try {
          const fetchSubtitlesAction = await dispatch(
            fetchSubtitles({
              video_id: videoId,
              words_per_line: value,
            })
          );

          return await dispatch(
            setSubtitles({
              items: fetchSubtitlesAction.payload.subtitles,
            })
          );
        } catch (response: any) {
          handleError(response.error, response.statusCode);
        }
      }

      const updatedTemplate = {
        ...selectedTemplateRef.current,
        [key]: value,
      };

      setSelectedTemplate(updatedTemplate);

      dispatch(
        setSubtitles({
          config: { ...updatedTemplate },
        })
      );
    },
    [
      selectedTemplate,
      selectedTemplateRef.current,
      subtitlesConfig,
      strokeEnabled,
    ]
  );

  const transformTemplateToData = (template: ISubtitlesConfig) => {
    return {
      label: template.templateName,
      font: template.font,
      value: template,
      styles: {
        fontFamily: template.font,
        color: template.color,
        highlightColor: template.highlightColor,
        highlightBackgroundColor: template.highlightBackgroundColor,
        ...(template.stroke && {
          textShadow: `3px 3px 10px ${template.strokeColor}`,
        }),
      },
      type: "template",
    };
  };

  const subtitleTemplates = SUBTITLE_TEMPLATES.map(
    (template: ISubtitlesConfig) => {
      return {
        ...template,
        size: subtitleDefaultSize,
      };
    }
  );

  const data: any = subtitleTemplates.map((template: ISubtitlesConfig) => {
    return transformTemplateToData(
      template.templateName === selectedTemplate.templateName
        ? selectedTemplate
        : template
    );
  });

  const isCustomTemplateSelected = selectedTemplate.templateName === "Custom";

  const customTemplate = {
    ...CUSTOM_TEMPLATE,
    size: subtitleDefaultSize,
  };

  data.push(
    transformTemplateToData(
      isCustomTemplateSelected ? selectedTemplate : customTemplate
    )
  );

  const fontsSelectData: any = FONTS.map((font: string) => {
    return {
      label: font,
      font: font,
      value: font,
      type: "font",
    };
  });

  const customTemplateOptions = () => (
    <Fragment>
      <div className="option">
        <Select
          transition="pop-top-left"
          transitionDuration={80}
          transitionTimingFunction="ease"
          classNames={{
            ...classes,
            item: classes.selectItem,
            dropdown: classes.selectDropDown,
          }}
          rightSection={<VFIconComponent type="arrow-down" size={14} />}
          label="Font"
          placeholder="Pick one"
          itemComponent={SelectItem}
          data={fontsSelectData}
          searchable
          maxDropdownHeight={400}
          defaultValue={selectedTemplate.font}
          nothingFound="Nobody here"
          onChange={(value) => handleInputChange("font", value)}
        />
      </div>
      <div className="option">
        <ColorInput
          label={"Color"}
          format="hex"
          defaultValue={selectedTemplate.color}
          classNames={classes}
          onChange={(value) => handleInputChange("color", value)}
        />
      </div>
      <div className="option">
        <ColorInput
          label={"Highlight color"}
          format="hex"
          defaultValue={selectedTemplate.highlightColor}
          classNames={classes}
          onChange={(value) => handleInputChange("highlightColor", value)}
        />
      </div>
      <div className="option">
        <ColorInput
          label={"Highlight background color"}
          format="hex"
          defaultValue={selectedTemplate.highlightBackgroundColor}
          classNames={classes}
          onChange={(value) =>
            handleInputChange("highlightBackgroundColor", value)
          }
        />
      </div>
      <div className="option">
        <Switch
          label="Stroke"
          labelPosition="left"
          size={"lg"}
          onLabel="ON"
          offLabel="OFF"
          checked={strokeEnabled}
          style={{ cursor: "pointer" }}
          classNames={{
            label: classes.label,
            body: classes.root,
            input: classes.switchInput,
            track: classes.switchTrack,
          }}
          onChange={async (e) => {
            const isChecked = e.target.checked;

            handleInputChange("stroke", isChecked);

            setStrokeEnabled(isChecked);
          }}
        />
      </div>
      {strokeEnabled && (
        <div className="option">
          <ColorInput
            label={"Stroke color"}
            format="hex"
            defaultValue={selectedTemplate.strokeColor}
            classNames={classes}
            onChange={(value) => handleInputChange("strokeColor", value)}
          />
        </div>
      )}
    </Fragment>
  );

  return (
    <div className="styles-holder">
      <div className="option">
        <Tooltip
          multiline
          width={220}
          withArrow
          transition="fade"
          transitionDuration={350}
          label="This option aims to limit words per subtitle line. However, to preserve meaning, the system may sometimes add an extra word."
        >
          <NumberInput
            step={1}
            rightSectionWidth={32}
            classNames={{
              ...classes,
              rightSection: classes.numberInputRightSection,
            }}
            defaultValue={3}
            label={"Words per line"}
            min={1}
            onChange={(value) => handleInputChange("wordsPerLine", value)}
          />
        </Tooltip>
      </div>
      <div className="option">
        <Select
          transition="pop-top-left"
          transitionDuration={80}
          transitionTimingFunction="ease"
          key={"subtitle_template"}
          classNames={{
            ...classes,
            item: classes.selectItem,
            dropdown: classes.selectDropDown,
            itemsWrapper: classes.itemsWrapper,
          }}
          rightSection={<VFIconComponent type="arrow-down" size={14} />}
          label="Template"
          placeholder="Pick a template"
          itemComponent={SelectItem}
          value={selectedTemplate}
          data={data}
          maxDropdownHeight={400}
          onChange={(value: ISubtitlesConfig) => {
            setSelectedTemplate(value);

            dispatch(
              setSubtitles({
                config: { ...value },
              })
            );
          }}
        />
      </div>
      <div
        className="option"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <span>Size</span>
        <Slider
          min={subtitleDefaultSize / 2}
          max={subtitleDefaultSize * 2}
          step={1}
          classNames={{
            root: classes.sliderRoot,
            bar: classes.sliderBar,
            thumb: classes.sliderThumb,
          }}
          defaultValue={subtitleDefaultSize}
          value={selectedTemplate.size}
          labelTransition="skew-down"
          labelTransitionDuration={150}
          labelTransitionTimingFunction="ease"
          onChange={(value: number) => handleInputChange("size", value)}
        />
      </div>
      {selectedTemplate.templateName === "Custom"
        ? customTemplateOptions()
        : null}
    </div>
  );
});

export default SubtitleStyles;
