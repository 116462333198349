import { showNotification } from "@mantine/notifications";
import { VIDEO_TITLE_MAX_LENGTH } from "./constants";
import { ISubtitle, IVideoSegment } from "./remotion/types";
import { ErrorResponse, TimelineZoomLevel } from "./types";
import VFIconComponent from "./components/icon/vf-icon";

const handleErrors = (err: any, errMsg?: string) => {
  showNotification({
    icon: <VFIconComponent type="error" backgroundColor="#FFFFFF" />,
    radius: "md",
    message: err?.data?.detail
      ? Array.isArray(err.data.detail)
        ? err.data.detail.map((error: any) => error.msg).join(", ")
        : err.data.detail
      : errMsg
      ? errMsg
      : "An error occurred",
  });
};

const formatTime = (timeInSeconds: number, pretty?: boolean): string => {
  const pad = (num: number, size: number) => num.toString().padStart(size, "0");

  const hours = Math.floor(timeInSeconds / 3600);
  const minutes = Math.floor((timeInSeconds % 3600) / 60);
  const seconds = Math.floor(timeInSeconds % 60);
  const milliseconds = Math.floor((timeInSeconds % 1) * 1000);

  // Format as HH:MM:SS.mmm
  return pretty
    ? `${pad(hours, 2)}h:${pad(minutes, 2)}m:${pad(seconds, 2)}s`
    : `${pad(hours, 2)}:${pad(minutes, 2)}:${pad(seconds, 2)}.${pad(
        milliseconds,
        3
      )}`;
};

// function to convert HH:MM:SS.sss to seconds
const parseTime = (time: string): number => {
  const parts = time.split(":");

  return (
    parseInt(parts[0], 10) * 3600 +
    parseInt(parts[1], 10) * 60 +
    parseFloat(parts[2])
  );
};

const formatFileSize = (bytes: number): string => {
  const MB = 1024 * 1024;
  const sizeInMB = bytes / MB;

  if (sizeInMB < 1) {
    return `${sizeInMB.toFixed(2)} MB`;
  }

  if (sizeInMB >= 1000) {
    const GB = sizeInMB / 1024;
    return `${GB.toFixed(1)} GB`;
  }

  // For sizes between 1MB and 999MB
  if (sizeInMB % 1 === 0) {
    return `${sizeInMB.toFixed(0)} MB`;
  }

  return `${sizeInMB.toFixed(1)} MB`;
};

const formatEntityTitle = (title: string, length?: number): string => {
  const maxLength: number = length || VIDEO_TITLE_MAX_LENGTH;

  return title.length > maxLength ? `${title.slice(0, maxLength)}...` : title;
};

const getPreviousPath = (pathname: string): string => {
  const paths = pathname.split("/");
  paths.pop();
  return paths.join("/");
};

const getFrameDimensions = (
  ratio: string,
  videoPlayerDimensions: { width: number; height: number }
): { frameWidth: number; frameHeight: number } => {
  if (ratio === "original" || ratio === "16:9") {
    return {
      frameWidth: videoPlayerDimensions.width,
      frameHeight: videoPlayerDimensions.height,
    };
  }

  let frameWidth, frameHeight;

  const isPortrait: boolean = isVideoPortrait(videoPlayerDimensions);
  const baseDimension: number = isPortrait
    ? videoPlayerDimensions.width
    : videoPlayerDimensions.height;

  switch (ratio) {
    case "9:16":
      frameWidth = isPortrait ? baseDimension : baseDimension * 0.5625; // 9/16
      frameHeight = isPortrait ? baseDimension / 0.5625 : baseDimension;
      break;

    case "4:5":
      frameWidth = isPortrait ? baseDimension : baseDimension * 0.8; // 4/5
      frameHeight = isPortrait ? baseDimension / 0.8 : baseDimension;
      break;

    case "2:3":
      frameWidth = isPortrait ? baseDimension : baseDimension * 0.66; // 2/3
      frameHeight = isPortrait ? baseDimension / 0.66 : baseDimension;
      break;

    case "1:1":
      frameWidth = baseDimension;
      frameHeight = baseDimension;
      break;

    default:
      throw new Error(`Unsupported aspect ratio: ${ratio}`);
  }

  return { frameWidth, frameHeight };
};

const isVideoPortrait = (videoDimensions: {
  width: number;
  height: number;
}): boolean => {
  return videoDimensions.height > videoDimensions.width;
};

const binarySearch = (
  subtitles: ISubtitle[],
  target: number,
  compare: (sub_time: number, b: number) => boolean
): number => {
  let low = 0;
  let high = subtitles.length - 1;

  while (low <= high) {
    const mid = Math.floor((low + high) / 2);
    if (compare(subtitles[mid].start, target)) {
      high = mid - 1;
    } else {
      low = mid + 1;
    }
  }

  return low;
};

const filterSubtitles = (
  subtitles: ISubtitle[],
  start: number,
  end: number
): ISubtitle[] => {
  const startIndex = binarySearch(subtitles, start, (a, b) => a > b);
  const endIndex = binarySearch(subtitles, end, (a, b) => a >= b);

  // Ensure the indices are within bounds and handle edge cases
  const filteredSubtitles = subtitles.slice(
    Math.max(0, startIndex - 1),
    Math.min(subtitles.length, endIndex + 1)
  );

  return filteredSubtitles;
};

const throttle = (fn: Function, wait: number) => {
  let lastTime = 0;
  return (...args) => {
    const now = new Date().getTime();

    if (now - lastTime >= wait) {
      fn.apply(this, args);
      lastTime = now;
    }
  };
};

const deepMerge = <T,>(target: T, source: Partial<T>): T => {
  if (typeof target !== "object" || typeof source !== "object") return target;
  const output = { ...target } as any;
  Object.keys(source).forEach((key) => {
    const typedKey = key as keyof T;
    if (target && source[typedKey] instanceof Object && typedKey in target) {
      output[typedKey] = deepMerge(
        target[typedKey],
        source[typedKey] as Partial<T[keyof T]>
      );
    } else {
      output[typedKey] = source[typedKey];
    }
  });
  return output as T;
};

const generateTimelineZoomLevels = (
  videoDuration: number,
  totalTimelineLength: number
): TimelineZoomLevel[] => {
  const minIntervalsCount = 4;
  const maxIntervalsCount = 8;
  const minZoomLevels = 5;
  const maxZoomLevels = 10;
  const commonIntervals = [5, 10, 30, 60, 300, 600, 1800, 3600];

  function findNearestInterval(target: number): number {
    return commonIntervals.reduce((prev, curr) =>
      Math.abs(curr - target) < Math.abs(prev - target) ? curr : prev
    );
  }

  // Calculate default zoom level
  let defaultInterval = findNearestInterval(
    Math.max(5, Math.floor(videoDuration / maxIntervalsCount))
  );
  let defaultSpaceBetween = Math.floor(
    totalTimelineLength / Math.ceil(videoDuration / defaultInterval)
  );

  // Adjust defaultInterval and defaultSpaceBetween to fit within totalTimelineLength
  while (
    defaultSpaceBetween * Math.ceil(videoDuration / defaultInterval) >
    totalTimelineLength
  ) {
    const nextInterval = findNearestInterval(defaultInterval + 1);
    if (nextInterval === defaultInterval) break; // No larger interval available
    defaultInterval = nextInterval;
    defaultSpaceBetween = Math.floor(
      totalTimelineLength / Math.ceil(videoDuration / defaultInterval)
    );
  }

  const zoomLevels: TimelineZoomLevel[] = [
    {
      index: 0,
      interval: defaultInterval,
      spaceBetweenIntervals: defaultSpaceBetween,
      default: true,
    },
  ];

  // Find suitable intervals
  const suitableIntervals = Array.from(
    new Set(commonIntervals.filter((interval) => interval <= defaultInterval))
  );
  const smallestInterval = Math.max(5, suitableIntervals[0]);

  // Determine number of zoom levels
  const zoomLevelCount = Math.min(
    maxZoomLevels,
    Math.max(minZoomLevels, suitableIntervals.length)
  );

  // Generate intermediate zoom levels
  const step = (suitableIntervals.length - 1) / (zoomLevelCount - 1);
  for (let i = 1; i < zoomLevelCount; i++) {
    const index = Math.floor(i * step);
    const interval = suitableIntervals[index];
    const spaceBetween = calculateSpaceBetween(
      interval,
      defaultInterval,
      defaultSpaceBetween,
      smallestInterval
    );

    zoomLevels.push({
      index: i,
      interval: interval,
      spaceBetweenIntervals: spaceBetween,
      default: false,
    });
  }

  // Adjust spaceBetweenIntervals to respect minIntervalsCount and maxIntervalsCount
  zoomLevels.forEach((level) => {
    const idealIntervalCount =
      totalTimelineLength / level.spaceBetweenIntervals;
    if (idealIntervalCount < minIntervalsCount) {
      level.spaceBetweenIntervals = Math.floor(
        totalTimelineLength / minIntervalsCount
      );
    } else if (idealIntervalCount > maxIntervalsCount) {
      level.spaceBetweenIntervals = Math.ceil(
        totalTimelineLength / maxIntervalsCount
      );
    }
  });

  // Sort zoom levels and remove duplicates
  zoomLevels.sort((a, b) => b.interval - a.interval);
  const uniqueZoomLevels = zoomLevels.filter(
    (level, index, self) =>
      index === self.findIndex((t) => t.interval === level.interval)
  );

  // Reassign indices after sorting and removing duplicates
  uniqueZoomLevels.forEach((level, idx) => (level.index = idx));

  return uniqueZoomLevels;
};

const calculateSpaceBetween = (
  interval: number,
  defaultInterval: number,
  defaultSpaceBetween: number,
  smallestInterval: number
): number => {
  const maxRatio = defaultInterval / smallestInterval;
  const currentRatio = defaultInterval / interval;
  const normalizedRatio = (currentRatio - 1) / (maxRatio - 1);

  // Use a power function to create a more dramatic curve
  const factor = Math.pow(normalizedRatio, 1.5) * 3 + 1;

  return Math.floor(defaultSpaceBetween * factor);
};

type MemoizedState = {
  lastIndex: number;
  subtitles: ISubtitle[];
};

// Helper function to check if a time is within a subtitle's range
const isTimeInSubtitle = (time: number, subtitle: ISubtitle): boolean => {
  return time >= subtitle.start && time < subtitle.end;
};

// Binary search function
const binarySearchSubtitle = (
  subtitles: ISubtitle[],
  currentTime: number
): number => {
  let left = 0;
  let right = subtitles.length - 1;

  while (left <= right) {
    const mid = Math.floor((left + right) / 2);
    const subtitle = subtitles[mid];

    if (isTimeInSubtitle(currentTime, subtitle)) {
      return mid;
    }

    if (currentTime < subtitle.start) {
      right = mid - 1;
    } else {
      left = mid + 1;
    }
  }

  return -1; // Not found
};

// Main search function with memoization
const findSubtitle = (() => {
  let memoizedState: MemoizedState | null = null;

  return (subtitles: ISubtitle[], currentTime: number): ISubtitle | null => {
    // Initialize or update memoized state if subtitles array has changed
    if (!memoizedState || memoizedState.subtitles !== subtitles) {
      memoizedState = { lastIndex: 0, subtitles };
    }

    const { lastIndex } = memoizedState;

    // Check if the last found subtitle is still valid
    if (isTimeInSubtitle(currentTime, subtitles[lastIndex])) {
      return subtitles[lastIndex];
    }

    // Perform binary search
    const foundIndex = binarySearchSubtitle(subtitles, currentTime);

    if (foundIndex !== -1) {
      memoizedState.lastIndex = foundIndex;
      return subtitles[foundIndex];
    }

    return null; // No subtitle found for the current time
  };
})();

const getClipDurationInSeconds = (segments: IVideoSegment[]): number => {
  if (!segments || segments.length === 0) return 0;

  return segments.reduce((acc: number, segment: IVideoSegment) => {
    return acc + (segment.end - segment.start);
  }, 0);
};

const parseErrorMessage = (error: ErrorResponse): string => {
  if (typeof error.detail === "string") {
    return error.detail;
  } else if (Array.isArray(error.detail) && error.detail.length) {
    return error.detail[0].msg;
  } else {
    return "An unexpected error occurred";
  }
};

const getSubtitleDefaultSize = (videoHeight: number): number =>
  Math.ceil(videoHeight / 14);

declare global {
  interface Window {
    dataLayer?: any[];
  }
}

const pushToDataLayer = (eventName: string, eventParams = {}) => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: eventName,
      ...eventParams,
    });
  }
};
export {
  handleErrors,
  formatTime,
  parseTime,
  formatEntityTitle,
  getPreviousPath,
  getFrameDimensions,
  isVideoPortrait,
  filterSubtitles,
  throttle,
  deepMerge,
  generateTimelineZoomLevels,
  findSubtitle,
  getClipDurationInSeconds,
  parseErrorMessage,
  getSubtitleDefaultSize,
  formatFileSize,
  pushToDataLayer,
};
