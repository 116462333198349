import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { apiSlice } from "./features/api/apiSlice";
import userSlice from "./features/user/userSlice";
import modalSlice from "./features/modal/modalSlice";
import videosSlice from "./features/videos/videosSlice";
import clipConfigSlice from "./features/clipConfig/clipConfigSlice";
import authSlice from "./features/auth/authSlice";
import sseMiddleware from "./features/middleware/sseMiddleware";

export const store = configureStore({
  reducer: {
    user: userSlice,
    [apiSlice.reducerPath]: apiSlice.reducer,
    modal: modalSlice,
    videos: videosSlice,
    clipConfig: clipConfigSlice,
    auth: authSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware, sseMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

setupListeners(store.dispatch);
