import { Button } from "@mantine/core";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FC, useEffect, useState } from "react";
import { showNotification } from "@mantine/notifications";
import { useMediaQuery } from "@mantine/hooks";
import { useDispatch, useSelector } from "react-redux";
import { selectAuthToken } from "../features/user/userSlice";
import { formatEntityTitle, getPreviousPath } from "../utils";
import VFIconComponent from "../components/icon/vf-icon";
import DeleteEntityModal from "../components/DeleteEntityModal";

export const detailsPageFactory = (config: any): FC => {
  return () => {
    const navigate = useNavigate(),
      dispatch = useDispatch(),
      location = useLocation(),
      { [config.entityIdName]: entityId } = useParams(),
      [deleteModalOpen, setDeleteModalOpen] = useState(false),
      [videoLoaded, setVideoLoaded] = useState(false),
      { data, isLoading, refetch } = config.getEntityQuery(entityId);

    const [isDeletionInProgress, setDeletionInProgress] = useState(false);

    const handleDeleteClick = (videoId: string) => {
      setDeleteModalOpen(true);
    };
    const handleConfirmDelete = async () => {
      setDeletionInProgress(true);

      try {
        await dispatch(config.deleteMutation(entityId)).unwrap();

        showNotification({
          radius: "md",
          title: "Success",
          message: `${config.entityRepresentation} deleted successfully.`,
          icon: <VFIconComponent type="success" backgroundColor="#FFFFFF" />,
        });

        setTimeout(() => {
          navigate(getPreviousPath(location.pathname));
        }, 500);
      } catch (error) {
        showNotification({
          radius: "md",
          title: "Error",
          message: `Error deleting ${config.entityRepresentation}.`,
          icon: <VFIconComponent type="error" backgroundColor="#FFFFFF" />,
        });
      }

      setDeletionInProgress(false);

      setDeleteModalOpen(false);
    };

    const largeScreen = useMediaQuery("(min-width: 992px)"),
      authToken = useSelector(selectAuthToken);

    const getDetailsContent = () => {
      return (
        <ul className="project-details-list">
          <li>
            <span className="label">Size:</span>
            <span className="value">{`${(
              data.media_metadata.file_size_bytes / 1000000
            ).toFixed(2)} MB`}</span>
          </li>
          <li>
            <span className="label">Duration:</span>
            <span className="value">{`${data.media_metadata.duration_seconds} secs`}</span>
          </li>
          <li>
            <span className="label">Video resolution:</span>
            <span className="value">{`${data.media_metadata.width} x ${data.media_metadata.height}`}</span>
          </li>

          <li>
            <span className="label">Date created:</span>
            <span className="value">
              {new Date(data.created_at).toLocaleString()}
            </span>
          </li>
          <li>
            <span className="label">Last modified:</span>
            <span className="value">
              {new Date(data.updated_at).toLocaleString()}
            </span>
          </li>
        </ul>
      );
    };

    useEffect(() => {
      // Update the document title using the browser API
      if (!authToken) navigate("/");
    }, [authToken]);

    // useEffect(() => {
    //   refetch();
    // }, [entityId]);

    return (
      <>
        {/* <LoadingOverlay
          visible={isLoading && !videoLoaded}
          loaderProps={{ size: "lg", variant: "dots" }}
        /> */}
        {data && (
          <div
            className="projects-details-page-holder"
            style={{ padding: "20px" }}
          >
            <div className="video-section">
              <div className="title-holder">
                <Button
                  className="back-btn vf-button-filled"
                  onClick={() => {
                    navigate(getPreviousPath(location.pathname));
                  }}
                >
                  Back
                </Button>
                <span className="video-title">
                  {formatEntityTitle(data?.title)}
                </span>
              </div>
              <div className="video-holder">
                <video
                  onLoadedMetadata={() => setVideoLoaded(true)}
                  poster={data?.thumbnail_path}
                  preload="auto"
                  controls
                >
                  <source src={`${data?.file_path}`} type="video/mp4" />
                </video>
              </div>
            </div>
            <div className="video-details-section">
              <div className="details-holder">
                <span className="title">{`${config.entityRepresentation} Details`}</span>

                {getDetailsContent()}
              </div>
              <div className="actions-holder">
                <div className="actions-title-holder">
                  <span className="title">Actions</span>
                </div>
                <div className="actions">
                  <Button
                    color="red"
                    onClick={() => handleDeleteClick(data.id)}
                  >
                    Delete
                  </Button>
                  {config.extra_buttons.map((button: any) => {
                    return (
                      <Button
                        className="vf-button-filled"
                        onClick={(event) =>
                          button.clickHandler(navigate, event, data?.title)
                        }
                      >
                        {button.label}
                      </Button>
                    );
                  })}
                  <a href={data.file_path}>
                    <Button className="vf-button-filled">Download</Button>
                  </a>
                </div>
              </div>
            </div>
            <DeleteEntityModal
              isLoading={isDeletionInProgress}
              isOpen={deleteModalOpen}
              onClose={() => setDeleteModalOpen(false)}
              entityRepresentation={config.entityRepresentation}
              handleConfirmDelete={handleConfirmDelete}
            />
          </div>
        )}
      </>
    );
  };
};
