import React, { useEffect } from "react";

const StripePricingTable = ({pricingTableId, publishableKey, customerEmail, clientReferenceId}) => {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://js.stripe.com/v3/pricing-table.js";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <stripe-pricing-table
            pricing-table-id={pricingTableId}
            publishable-key={publishableKey}
            customer-email={customerEmail}
            client-reference-id={clientReferenceId}
        />
    );
};

export default StripePricingTable;
