import { fetchEventSource } from "@microsoft/fetch-event-source";
import { SSEEventTypes, IVideoClip } from "../../types";
import { selectAuthToken } from "../user/userSlice";
import { updateVideo, updateVideoClip } from "../videos/videosSlice";
import { showNotification } from "@mantine/notifications";
import envConfig from "../../envConfig";
import { closeAllModals, closeModal, openModal } from "../modal/modalSlice";
import { ModalConfig } from "../modal/types";
import { getUserData } from "../auth/authActions";

let eventSource = null;
let controller = null;

// Middleware function
const sseMiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case "CONNECT_SSE":
      // Establish connection only if eventSource is not already created
      if (!eventSource) {
        const token = selectAuthToken(store.getState());
        const url = `${envConfig.baseApi}/notifications`;

        controller = new AbortController();
        eventSource = true;

        fetchEventSource(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          signal: controller.signal,
          openWhenHidden: true,
          onmessage: (event) => {
            if (event.data) {
              const data = JSON.parse(event.data);

              switch (data.type) {
                case SSEEventTypes.VIDEO_PROCESSED:
                  store.dispatch(updateVideo({ data: data.data }));

                  showNotification({
                    radius: "md",
                    title: "Subtitles are ready!",
                    message: "You can now add subtitles to the clip!",
                    color: "teal",
                  });
                  break;
                case SSEEventTypes.CLIP_PROCESSED:
                  const clipProcessedData: IVideoClip = data.data;

                  store.dispatch(updateVideoClip({ data: clipProcessedData }));

                  showNotification({
                    radius: "md",
                    title: "Your clip has been processed!",
                    message: "You can check it out in Clips page!",
                    color: "teal",
                  });
                  break;
                case SSEEventTypes.SUBSCRIPTION_UPGRADED:
                  store.dispatch(closeAllModals());

                  let modalConfig: ModalConfig = {
                    title: "Subscription upgrade completed!",
                    iconConfig: { type: "success" },
                    subtitle:
                      "Your plan upgrade was successful. You're now part of our community of professional creators. Let's make something amazing!",
                    closable: true,
                    actions: [
                      {
                        label: "Get Started",
                        onClick: () => {
                          store.dispatch(getUserData());
                          store.dispatch(closeModal());
                        },
                        variant: "filled",
                        size: "lg",
                      },
                    ],
                  };

                  store.dispatch(openModal(modalConfig));

                  break;
                case "error":
                  console.warn("Error received from SSE:", data.payload);
                  break;
                default:
                  console.warn("Unhandled event type:", data.type);
              }
            }
          },
          onopen: () => console.log("SSE connection opened."),
          onerror: (error) => {
            console.error("SSE connection error:", error);
            eventSource = null;
            controller = null;
          },
        });
      }
      break;

    case "DISCONNECT_SSE":
      if (controller) {
        controller.abort();
        eventSource = null;
        controller = null;
        console.log("SSE connection closed.");
      }

      break;
  }

  return next(action);
};

export default sseMiddleware;
