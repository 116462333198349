import { Link, useLocation } from "react-router-dom";
import "./Breadcrumbs.scss";
import React from "react";
import { useSelector } from "react-redux";
import {
  selectCurrentCut,
  selectCurrentProject,
} from "../features/user/userSlice";
import { formatEntityTitle } from "../utils";

function Breadcrumb(props: { inEditor?: boolean; alwaysShow?: boolean }) {
  const currentProject = useSelector(selectCurrentProject);
  const currentCut = useSelector(selectCurrentCut);
  const location = useLocation();
  const { state } = location;
  const parts = location.pathname.split("/").filter((part) => part);
  let currentPath = "";

  const getBreadcrumbTitle = (part: string, allParts: string[]) => {
    const reservedRouteKeywords = ["projects", "edit", "edits"];
    const entities = allParts.filter(
      (part) => reservedRouteKeywords.indexOf(part) === -1
    );

    switch (part) {
      case "projects":
        return "Projects";
      case "clips":
        return "Clips";
      case "edit":
        return "Video Editor";
      default:
        if (entities && entities.length) {
          if (entities.length === 1 || entities.indexOf(part) === 0) {
            return formatEntityTitle(
              state?.projectTitle || currentProject?.title || part
            );
          } else {
            return formatEntityTitle(
              state?.cutTitle || currentCut?.title || part
            );
          }
        } else {
          // fallback
          return formatEntityTitle(part);
        }
    }
  };

  const breadcrumbData = parts.map((part, index) => {
    currentPath += `/${part}`;

    return {
      title: getBreadcrumbTitle(part, parts),
      path: currentPath,
    };
  });

  const pathsWithoutBreadcrumbs = [
    "/projects/:projectId/clips/:editId/edit",
    "/projects/:projectId/edit",
  ];

  const isPathMatched = (path: string) => {
    const regexPath =
      "^" +
      path
        .replace(/:[^\s/]+/g, "([\\w-]+)") // Replace dynamic params with regex
        .replace(/\//g, "\\/") + // Escape slashes
      "$";

    const regex = new RegExp(regexPath);
    return location.pathname.match(regex);
  };

  const showBreadcrumbs =
    !pathsWithoutBreadcrumbs.some((path) => isPathMatched(path)) ||
    props.alwaysShow;

  return (
    showBreadcrumbs && (
      <nav className={`breadcrumbs-holder ${props.inEditor && "no-spaces"}`}>
        {breadcrumbData.map((data, index) => (
          <div className="breadcrumb" key={index}>
            <React.Fragment key={index}>
              <Link
                to={data.path}
                state={{
                  projectTitle: currentProject?.title,
                  cutTitle: currentCut?.title,
                }}
              >
                {data.title}
              </Link>
              {index < breadcrumbData.length - 1 && (
                <span className="breadcrumb-separator"> &gt; </span>
              )}
            </React.Fragment>
          </div>
        ))}
      </nav>
    )
  );
}

export default Breadcrumb;
