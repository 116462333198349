import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ModalConfig, ModalType } from "../features/modal/types";
import { useModal } from "./useModal";
import { ErrorResponse, ErrorResponseDetailType } from "../types";
import { parseErrorMessage } from "../utils";
import { useGetCustomerPortalUrlMutation } from "../features/api/apiSlice";

export const useErrorHandler = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { openModal, closeModal, closeAllModals } = useModal();
  const [getCustomerPortalUrl, { data: customerPortal }] =
    useGetCustomerPortalUrlMutation();

  const handleError = (error: ErrorResponse, statusCode: number) => {
    let modalConfig: ModalConfig;

    if (error) {
      const message = parseErrorMessage(error);

      dispatch(closeAllModals());

      switch (statusCode) {
        // Invalid credentials
        case 401:
          modalConfig = {
            title: "You are not authorized",
            iconConfig: { type: "locker" },
            subtitle: "Invalid credentials",
            closable: false,
            actions: [
              {
                label: "Okay",
                onClick: () => {
                  navigate("/login");

                  dispatch(closeModal());
                },
                variant: "filled",
                size: "lg",
              },
            ],
          };

          dispatch(openModal(modalConfig));

          break;
        // Trial expired
        // Quota exceeded
        case 403:
          const errorResponseType: ErrorResponseDetailType =
            error?.detail[0]?.type;

          if (errorResponseType === ErrorResponseDetailType.TRIAL_EXPIRED) {
            modalConfig = {
              title: "Oops! Your Trial Has Ended",
              iconConfig: { type: "warning" },
              subtitle:
                "Your trial has ended, but the journey continues. Upgrade your plan now to join thousands of pro editors and take your skills to the next level.",
              closable: false,
              actions: [
                {
                  label: "Upgrade Now",
                  onClick: () => {
                    const nextModalConfig: ModalConfig = {
                      type: ModalType.SUBSCRIPTION,
                    };

                    dispatch(closeModal());

                    dispatch(openModal(nextModalConfig));
                  },
                  variant: "filled",
                  size: "lg",
                },
              ],
            };

            dispatch(openModal(modalConfig));
          } else if (
            errorResponseType ===
              ErrorResponseDetailType.QUOTA_EXCEEDED_STORAGE ||
            errorResponseType ===
              ErrorResponseDetailType.QUOTA_EXCEEDED_UPLOAD ||
            errorResponseType ===
              ErrorResponseDetailType.QUOTA_EXCEEDED_EXPORT ||
            errorResponseType === ErrorResponseDetailType.NO_SUBSCRIPTION
          ) {
            const title =
              errorResponseType === ErrorResponseDetailType.NO_SUBSCRIPTION
                ? "Your subscription has expired"
                : "You have exceeded your usage quota";

            const subtitle =
              errorResponseType === ErrorResponseDetailType.NO_SUBSCRIPTION
                ? "Your premium projects are saved but require a active subscription to edit. Resubscribe to pick up where you left off"
                : message;

            modalConfig = {
              title,
              iconConfig: { type: "warning" },
              subtitle,
              closable: false,
              actions: [
                {
                  label: "Okay, let's go",
                  onClick: async () => {
                    const data = await getCustomerPortalUrl().unwrap();

                    window.open(data.url, "_blank");
                  },
                  variant: "filled",
                  size: "lg",
                },
              ],
            };

            dispatch(openModal(modalConfig));
          }

          break;
        default:
          modalConfig = {
            title: "Oops! Something went wrong!",
            iconConfig: { type: "error" },
            subtitle: message,
            closable: true,
            actions: [
              {
                label: "Okay",
                onClick: () => {
                  dispatch(closeModal());
                },
                variant: "filled",
                size: "lg",
              },
            ],
          };

          dispatch(openModal(modalConfig));
      }
    } else {
      modalConfig = {
        title: "Oops! Something went wrong!",
        iconConfig: { type: "error" },
        closable: true,
        actions: [
          {
            label: "Okay",
            onClick: () => {
              dispatch(closeModal());
            },
            variant: "filled",
            size: "lg",
          },
        ],
      };

      dispatch(openModal(modalConfig));
    }
  };

  return { handleError };
};
