import { Tabs, createStyles } from "@mantine/core";
import { IToolTab, ICaption } from "../../types";
import CaptionsList from "../captions-list/CaptionsList";
import "./CaptionsTabs.scss";
import { ISubtitle } from "../../remotion/types";
import { memo, useEffect, useMemo } from "react";
import SubtitleStyles from "../subtitles-styles/SubtitleStyles";

const useStyles = createStyles(() => ({
  tabsList: {
    display: "block",
    paddingLeft: "30px",
    border: "none",
    button: {
      margin: 0,
      border: "none",
      display: "inline-block",
      padding: "7px 16px",
      borderTopRightRadius: "10px",
      borderTopLeftRadius: "10px",
      backgroundColor: "#F5F5F5",
      "&[data-active]": {
        backgroundColor: "#29B1A1",
        color: "#FFF",
      },
    },
  },
  tabsPanel: {
    padding: "24px 18px",
    border: "1px solid #29B1A1",
    borderRadius: "16px",
    marginBottom: "25px",
  },
}));

interface CaptionsTabsProps {
  tabs: IToolTab[];
  shouldShowObject: (object: IToolTab) => boolean;
  handleSubtitlesChange: (captions: ISubtitle[]) => void;
  videoPlayerRef: any;
  videoId: string;
}

const CaptionsTabs = memo(
  ({
    tabs,
    shouldShowObject,
    handleSubtitlesChange,
    videoPlayerRef,
    videoId,
  }: CaptionsTabsProps) => {
    const { classes } = useStyles();

    let selectedTabName: string = "",
      onlyOneTabToShow: boolean = false;

    selectedTabName = tabs[0].title;

    // set onlyOneTabToShow if only one tab with showFor condition checked with shouldShowObject
    onlyOneTabToShow = useMemo(
      () => tabs.filter((tab: any) => shouldShowObject(tab)).length === 1,
      [tabs]
    );

    const tabContent = (tab: IToolTab) => {
      return tab.title === "Edit Subtitles" ? (
        <div>
          <CaptionsList
            captions={tab.options as ICaption[]}
            onChange={handleSubtitlesChange}
            videoPlayerRef={videoPlayerRef}
          />
        </div>
      ) : (
        <SubtitleStyles videoId={videoId} />
      );
    };

    return (
      <div>
        <Tabs
          defaultValue={selectedTabName}
          classNames={{
            tabsList: classes.tabsList,
            panel: classes.tabsPanel,
          }}
        >
          <Tabs.List
            position={"center"}
            grow={true}
            className={onlyOneTabToShow ? "hidden" : ""}
          >
            {tabs.map((tab: IToolTab) => {
              return shouldShowObject(tab) ? (
                <Tabs.Tab key={tab.title} value={tab.title}>
                  {tab.title}
                </Tabs.Tab>
              ) : null;
            })}
          </Tabs.List>
          {tabs.map((tab: IToolTab) => {
            return shouldShowObject(tab) ? (
              <Tabs.Panel value={tab.title} key={tab.title}>
                <div
                  className={`tab-content ${
                    tab.title === "Edit Subtitles" ? "captions" : "styles"
                  }`}
                >
                  {tabContent(tab)}
                </div>
              </Tabs.Panel>
            ) : null;
          })}
        </Tabs>
      </div>
    );
  }
);

CaptionsTabs.displayName = "CaptionsTabs";

export default CaptionsTabs;
