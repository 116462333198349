import React, { FC } from "react";
import { Navigate } from "react-router-dom";
import useAuth from "../../hooks/useAuthCheck";
import { LoadingOverlay } from "@mantine/core";

interface ProtectedRouteProps {
  element: React.ReactElement;
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({ element }) => {
  const { isAuthenticated, loading } = useAuth();

  if (loading) {
    return (
      <LoadingOverlay
        visible={true}
        loaderProps={{ size: "lg", variant: "dots" }}
      />
    );
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return element;
};

export default ProtectedRoute;
