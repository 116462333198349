import { Button, Skeleton, Tooltip } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import "./SourceVideoCard.scss";
import { ProcessingStatus } from "../types";
import { useState } from "react";
import { Hourglass } from "react-loader-spinner";
import failedIcon from "../assets/icons/failed.svg";

export default function SourceVideoCard(props: {
  entityType: "video" | "clip";
  thumbnailUrl: string;
  title: string;
  id: string;
  handleAction: Function;
  buttons: Array<any>;
  renderingStatus: ProcessingStatus;
}) {
  const largeScreen = useMediaQuery("(min-width: 992px)");
  const [thumbnailLoaded, setThumbnailLoaded] = useState<boolean>(false);

  const checkProcessingStatus = (status: string) => {
    return status === props.renderingStatus;
  };

  const isInProcessingStatus: boolean = checkProcessingStatus(
    ProcessingStatus.PROCESSING
  );

  const isInErrorStatus: boolean = checkProcessingStatus(
    ProcessingStatus.ERROR
  );

  const isInSuccessStatus: boolean = checkProcessingStatus(
    ProcessingStatus.SUCCESS
  );

  const processedClipIsLoading = !thumbnailLoaded && isInSuccessStatus;

  const skeletonContent = (
    <div className="video-card">
      <div className="thumbnail-holder">
        <Skeleton height={140} width={250} />
      </div>
      <div className="title-holder">
        <Skeleton style={{ margin: "0 auto" }} height={20} width="60%" />
      </div>
      <div className="actions-holder">
        <Skeleton style={{ margin: 5 }} height={35} width="100%" />
        <Skeleton style={{ margin: 5 }} height={35} width="100%" />
      </div>
    </div>
  );

  return (
    <>
      {processedClipIsLoading && skeletonContent}
      <Tooltip
        disabled={isInSuccessStatus || isInErrorStatus}
        multiline
        width={250}
        transition="fade"
        transitionDuration={350}
        label={`This ${props.entityType} is currently being processed. You will be notified when it's ready.`}
        position="top"
        withArrow
      >
        <div
          className={`video-card ${props.renderingStatus.toLowerCase()} ${
            processedClipIsLoading ? "loading" : ""
          }`}
        >
          <div className="thumbnail-holder">
            {isInErrorStatus && (
              <img
                className="clip-error-icon"
                src={failedIcon}
                alt="Clip error icon"
              />
            )}

            {isInProcessingStatus && (
              <div
                style={{
                  display: "block",
                  width: "200px",
                  height: "100px",
                  position: "relative",
                }}
              >
                <Hourglass
                  visible={true}
                  height="40"
                  width="40"
                  ariaLabel="hourglass-loading"
                  wrapperStyle={{
                    display: "block",
                    margin: "50px auto 0px auto",
                  }}
                  wrapperClass=""
                  colors={["#29b1a1", "#d1edeb"]}
                />
              </div>
            )}

            {!!props.thumbnailUrl && (
              <img
                onLoad={() => setThumbnailLoaded(true)}
                src={props.thumbnailUrl}
                style={{
                  visibility:
                    !thumbnailLoaded && !isInProcessingStatus
                      ? "hidden"
                      : "visible",
                }}
                alt="Clip thumbnail"
              />
            )}
          </div>
          <div className="title-holder">
            <span>{props.title}</span>
          </div>
          <div className="actions-holder">
            {props.buttons.map((button) => {
              return (
                <Button
                  disabled={checkProcessingStatus(ProcessingStatus.ERROR)}
                  loading={isInProcessingStatus}
                  loaderProps={{ size: "xs", color: "#29b1a1" }}
                  loaderPosition="center"
                  key={button.title}
                  className={"vf-button-outlined"}
                  fullWidth
                  size={largeScreen ? "sm" : "xs"}
                  variant="outline"
                  onClick={() =>
                    props.handleAction(button.action, props.id, props.title)
                  }
                >
                  {!isInProcessingStatus ? button.title : ""}
                </Button>
              );
            })}
          </div>
        </div>
      </Tooltip>
    </>
  );
}
