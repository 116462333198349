// Random SHA-256, because the user is unlikely to name his video like this.

import { ISubtitlesConfig } from "./remotion/types";

const VIDEO_ACTIONS_MAP = {
  NEW_EDIT: "new-edit",
  VIDEO_EDITS: "video-edits",
  VIDEO_DETAILS: "video-details",
};

const EDIT_ACTIONS_MAP = {
  EDIT_DETAILS: "edit-details",
  DELETE_EDIT: "delete-edit",
  EDIT: "edit",
};

const VIDEO_TITLE_MAX_LENGTH = 15;

const FONTS = [
  "Arimo",
  "DejaVuSans",
  "Futura",
  "Impact",
  "RobotoSlab",
  "TimesNewRoman",
  "Ubuntu",
];

const DEFAULT_SUBTITLES_WORDS_PER_LINE = 3;

const SUBTITLE_TEMPLATES: ISubtitlesConfig[] = [
  {
    templateName: "Bold Impact",
    size: 80,
    font: "Impact",
    color: "#FFFFFF",
    strokeColor: "#000000",
    highlightColor: "#FF4500",
    highlightBackgroundColor: "#FFFFFF",
    stroke: true,
  },
  {
    templateName: "Sleek Futuristic",
    size: 65,
    font: "Futura",
    color: "#F0F0F0",
    strokeColor: "#333333",
    highlightColor: "#00FFFF",
    highlightBackgroundColor: "#333333",
    stroke: false,
  },
  {
    templateName: "Vibrant Ubuntu",
    size: 75,
    font: "Ubuntu",
    color: "#FFFF00",
    strokeColor: "#800080",
    highlightColor: "#FF1493",
    highlightBackgroundColor: "#000000",
    stroke: true,
  },
  {
    templateName: "Classic Times",
    size: 70,
    font: "TimesNewRoman",
    color: "#E0E0E0",
    strokeColor: "#2F4F4F",
    highlightColor: "#FFD700",
    highlightBackgroundColor: "#2F4F4F",
    stroke: true,
  },
  {
    templateName: "Clean DejaVu",
    size: 72,
    font: "DejaVuSans",
    color: "#FFFFFF",
    strokeColor: "#000000",
    highlightColor: "#32CD32",
    highlightBackgroundColor: "#000000",
    stroke: false,
  },
  {
    templateName: "Minimalist Arimo",
    size: 68,
    font: "Arimo",
    color: "#EFEFEF",
    strokeColor: "#1A1A1A",
    highlightColor: "#FF6347",
    highlightBackgroundColor: "#1A1A1A",
    stroke: false,
  },
  {
    templateName: "Elegant Slab",
    size: 76,
    font: "RobotoSlab",
    color: "#F5F5F5",
    strokeColor: "#2C3E50",
    highlightColor: "#E74C3C",
    highlightBackgroundColor: "#ECF0F1",
    stroke: true,
  },
  {
    templateName: "Neon Nights",
    size: 82,
    font: "Impact",
    color: "#39FF14",
    strokeColor: "#FF00FF",
    highlightColor: "#00FFFF",
    highlightBackgroundColor: "#000000",
    stroke: true,
  },
  {
    templateName: "Pastel Dream",
    size: 70,
    font: "Ubuntu",
    color: "#FFB6C1",
    strokeColor: "#87CEFA",
    highlightColor: "#FAFAD2",
    highlightBackgroundColor: "#DDA0DD",
    stroke: true,
  },
  {
    templateName: "Monochrome Chic",
    size: 74,
    font: "Futura",
    color: "#FFFFFF",
    strokeColor: "#000000",
    highlightColor: "#808080",
    highlightBackgroundColor: "#FFFFFF",
    stroke: false,
  },
];

const SUBTITLE_STROKE_WIDTH = 3;

export {
  VIDEO_ACTIONS_MAP,
  EDIT_ACTIONS_MAP,
  VIDEO_TITLE_MAX_LENGTH,
  FONTS,
  DEFAULT_SUBTITLES_WORDS_PER_LINE,
  SUBTITLE_TEMPLATES,
  SUBTITLE_STROKE_WIDTH,
};
